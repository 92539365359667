import styled from 'styled-components/macro';

const Root = styled.div`
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

const ContentWrap = styled.div`
    flex-grow: 1;
    overflow-y: hidden;
    @media (max-width: 800px) {
        overflow-y: auto;
    }
`;

export { Root, ContentWrap };
