import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './chat-info-card.styles';
import { InfoLine } from '../../../../global/components/info-line';
import { getChatInfoCard } from '../../redux/chat-info.selectors';
import { clearChatInfoCard, loadChatInfoCard } from '../../redux/chat-info.actions';
import UpdateLinkModal from '../../components/update-link-modal/update-link-modal';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useParams } from 'react-router-dom';

const ChatInfoCard = () => {
    const params = useParams();
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [openUpdateLinkModal, setOpenUpdateLinkModal] = useState<boolean>(false);
    const chatInfoCard = useSelector(getChatInfoCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearChatInfoCard());
            setId(Number(params?.id));
            spinnerRunner(loadChatInfoCard(Number(params?.id)));
        }
    }, [params?.id]);

    const openUpdateLinkModalCallback = useCallback(() => {
        setOpenUpdateLinkModal(true);
    }, [setOpenUpdateLinkModal]);

    const closeUpdateLinkModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenUpdateLinkModal(false);
            if (withUpdate) {
                spinnerRunner(loadChatInfoCard(Number(params?.id)));
            }
        },
        [setOpenUpdateLinkModal, spinnerRunner, loadChatInfoCard, params?.id],
    );

    const goToList = useCallback(() => {
        goToState('/chat-info');
    }, []);

    if (!chatInfoCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button title="Обновить ссылки" onClick={openUpdateLinkModalCallback} />
            </Header>
            <InfoLine>
                <InfoItem title="id в телеграмме" value={chatInfoCard.chatId} />
                <InfoItem title="Название чата" value={chatInfoCard.chatName} />
                <InfoItem title="Описание чата" value={chatInfoCard.chatDescription} />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Связанная группа"
                    value={
                        <div>
                            {chatInfoCard.group && (
                                <a href={`#/groups/${chatInfoCard.group.id}`}>{chatInfoCard.group.name}</a>
                            )}
                        </div>
                    }
                />
                <InfoItem
                    title="Связанное служение"
                    value={
                        <div>
                            {chatInfoCard.ministry && (
                                <a href={`#/ministries/${chatInfoCard.ministry.id}`}>{chatInfoCard.ministry.name}</a>
                            )}
                        </div>
                    }
                />
            </InfoLine>
            {!!openUpdateLinkModal && (
                <UpdateLinkModal chatInfo={chatInfoCard} onClose={closeUpdateLinkModalCallback} />
            )}
            <BottomPadding />
        </Root>
    );
};

export default ChatInfoCard;
