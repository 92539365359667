import styled from 'styled-components/macro';
import { colors } from '../../styles/variables';
import { ClipLoader } from 'react-spinners';

const SpinnerWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${colors.white};
    opacity: 0.5;
`;

const LocalSpinner = styled(ClipLoader)({
    position: 'absolute',
    display: 'block',
    margin: '0 auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
});

const BottomPadding = styled.div`
    width: 100%;
    height: 50px;
`;

export { SpinnerWrap, LocalSpinner, BottomPadding };
