import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './settings-edit.styles';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { getSettingsCard } from '../../redux/service.selectors';
import { clearSettingsCard, loadSettingsCard, updateSettingsCard } from '../../redux/service.actions';
import { CreateSettingsParams } from '../../types/create-settings-params';

const SettingsEdit = () => {
    const availablePage = useMemo(() => AuthService.availableForAdmin() || AuthService.hasServicePermission(), []);
    const dispatch = useDispatch();
    const settingsCard = useSelector(getSettingsCard);
    const spinnerRunner = useSpinner(dispatch);
    const [plan, setPlan] = useState<string | null>(null);
    const [translationLink, setTranslationLink] = useState<string | null>(null);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        dispatch(clearSettingsCard());
        spinnerRunner(loadSettingsCard());
    }, []);

    useEffect(() => {
        if (settingsCard) {
            setPlan(settingsCard.plan);
            setTranslationLink(settingsCard.translationLink);
        }
    }, [settingsCard]);

    const saveCallback = useCallback(() => {
        const params: CreateSettingsParams = {
            plan,
            translationLink,
        };
        spinnerRunner(updateSettingsCard(params));
    }, [plan, translationLink, updateSettingsCard, spinnerRunner]);

    if (!settingsCard) {
        return (
            <Root>
                <Header></Header>
            </Root>
        );
    }

    return (
        <Root>
            <InfoItem
                title="Ссылка на трансляцию"
                value={<TextInput value={translationLink} onChange={setTranslationLink} asTextarea />}
            />
            <InfoItem
                title="План собрания"
                value={
                    <TextInput
                        value={plan}
                        onChange={setPlan}
                        asTextarea
                        style={{
                            height: '300px',
                        }}
                    />
                }
            />
            <Footer>
                <Button title="Сохранить" onClick={saveCallback} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default SettingsEdit;
