import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './group-edit.styles';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect, parseMemberItemToEntityForSelect } from '../../../members/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { clearGroupCard, loadGroupCard, updateGroupCard } from '../../redux/groups.actions';
import { getGroupCard } from '../../redux/groups.selectors';
import { CreateGroupParams } from '../../types/create-group-params';
import { getNumberCoordByString } from '../../../../global/helpers/map.helper';
import MapComponent, { MapObject } from '../../../../global/components/map/map';
import { loadChatInfoForSelect, parseChatInfoItemToEntityForSelect } from '../../../chat-info/helpers/api.helpers';
import { AuthService } from '../../../../auth/services/AuthService';
import { DayItem, dayOptions } from '../../../daily-messages/models/day.model';
import RunTime from '../../../daily-messages/components/run-time/run-time';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useParams } from 'react-router-dom';

const GroupEdit = () => {
    const params = useParams();
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const dispatch = useDispatch();
    const groupCard = useSelector(getGroupCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [district, setDistrict] = useState<string | null>(null);
    const [lead, setLead] = useState<EntityForSelect | null>(null);
    const [assist, setAssist] = useState<EntityForSelect | null>(null);
    const [coords, setCoords] = useState<string | null>(null);
    const [day, setDay] = useState<DayItem | null>(null);
    const [startTime, setStartTime] = useState<string | null>(null);

    const editAvailableAsLeader = useMemo(() => {
        const member = AuthService.getUserInfo()?.member;
        if (!member || !groupCard || !groupCard.lead) {
            return false;
        }
        return member.id === groupCard.lead.id || (groupCard.assist && member.id === groupCard.assist.id);
    }, [groupCard]);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (!showForLeader) {
            return;
        }
        if (!!groupCard && !showForAdmin && !editAvailableAsLeader) {
            goToState('/');
            return;
        }
    }, [editAvailableAsLeader]);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearGroupCard());
            setId(Number(params?.id));
            dispatch(loadGroupCard(Number(params?.id)));
        }
    }, [params?.id]);

    useEffect(() => {
        if (groupCard) {
            setId(groupCard.id);
            setName(groupCard.name);
            setChatInfo(groupCard.chat ? parseChatInfoItemToEntityForSelect(groupCard.chat) : null);
            setDistrict(groupCard.district);
            setCoords(groupCard.coords);
            setDay(groupCard.day);
            setStartTime(groupCard.startTime);
            setLead(parseMemberItemToEntityForSelect(groupCard.lead));
            setAssist(groupCard.assist ? parseMemberItemToEntityForSelect(groupCard.assist) : null);
        }
    }, [groupCard]);

    const isValidForm = useMemo(
        () => !!name && !!lead && !!district && !!coords && !!day && !!startTime,
        [name, lead, district, coords, day, startTime],
    );

    const saveCallback = useCallback(() => {
        if (!id || !name || !lead || !district || !coords || !day || !startTime) {
            return;
        }
        const params: CreateGroupParams = {
            name: name,
            chatInfoId: chatInfo?.id || null,
            district,
            leadId: lead.id,
            assistId: assist?.id || null,
            coords,
            day: Number(day.value),
            startTime,
        };
        spinnerRunner(updateGroupCard(id, params));
    }, [name, day, startTime, chatInfo, district, lead, assist, coords, spinnerRunner, updateGroupCard]);

    const goToList = useCallback(() => {
        goToState('/groups');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/groups/${params?.id}`);
    }, []);

    const coordsValue = useMemo(() => {
        if (!coords) {
            return null;
        }
        return getNumberCoordByString(coords);
    }, [coords, getNumberCoordByString]);

    const updateCoordsCallback = useCallback(
        (newCoords: number[]) => {
            setCoords(`${newCoords[0]},${newCoords[1]}`);
        },
        [setCoords],
    );

    const mapObjects = useMemo<MapObject[]>(() => {
        return [
            {
                coords: coordsValue,
                updateCoordsCallback,
            },
        ];
    }, [coordsValue, updateCoordsCallback]);

    if (!groupCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <InfoItem title="Вы редактируете группу" size={'h1'} />
            <InfoLine>
                <InfoItem title="Название *" value={<TextInput value={name} onChange={setName} />} />
                <InfoItem title="Район *" value={<TextInput value={district} onChange={setDistrict} />} />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="День проведения *"
                    value={<Select options={dayOptions} defaultValue={day} value={day} onChange={setDay} isClearable />}
                />
                <InfoItem title="Время начала *" value={<RunTime value={startTime} onChange={setStartTime} />} />
            </InfoLine>
            {!!showForAdmin && (
                <InfoLine>
                    <SingleSelectEntity
                        label="Лидер *"
                        value={lead}
                        onChange={setLead}
                        loadOptions={loadMembersForSelect}
                    />
                    <SingleSelectEntity
                        label="Второй лидер"
                        value={assist}
                        onChange={setAssist}
                        loadOptions={loadMembersForSelect}
                    />
                </InfoLine>
            )}
            {!showForAdmin && (
                <InfoLine>
                    <InfoItem
                        title="Лидер"
                        value={
                            <div>
                                {!!groupCard.lead && (
                                    <a href={`#/members/${groupCard.lead.id}`}>{groupCard.lead.name}</a>
                                )}
                            </div>
                        }
                    />
                    <InfoItem
                        title="Второй лидер"
                        value={
                            <div>
                                {!!groupCard.assist && (
                                    <a href={`#/members/${groupCard.assist.id}`}>{groupCard.assist.name}</a>
                                )}
                            </div>
                        }
                    />
                </InfoLine>
            )}
            {!!coordsValue && <InfoItem title="Координаты *" value={<MapComponent objects={mapObjects} />} />}
            <Footer>
                <Button title="Сохранить" onClick={saveCallback} disabled={!isValidForm} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default GroupEdit;
