import styled from 'styled-components/macro';
import { colors, palette } from '../../../../global/styles/variables';

const Root = styled.div`
    width: 100%;
    min-height: 200px;
    padding: 12px;
    box-sizing: border-box;
    position: relative;

    .min-action-icon {
        cursor: pointer;
        margin-left: 6px;
        width: 17px;
        height: 17px;
        position: relative;
        top: 2px;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;

    & > div {
        margin-right: 18px;
    }
`;

const FilterPanel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    & > div,
    & > label {
        flex-shrink: 0;
    }

    .clear-filter-button {
        margin: 25px 0 0 12px;
    }

    .filter-item {
        margin-right: 12px;
    }

    .filter-item-checkbox {
        margin-left: 5px;
        margin-right: 12px;
        margin-top: 15px;
    }
`;

const LabelWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const RedLabel = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background: ${palette.red};
    color: ${palette.white};
    margin: 4px 0;

    &.gray {
        background: ${palette.grayLight};
        color: ${colors.black};
    }
`;

const GreenLabel = styled.div`
    padding: 2px 4px;
    border-radius: 4px;
    background: ${palette.green};
    color: ${palette.white};
    margin: 4px 0;
`;

const FilterOverflowWrap = styled.div`
    max-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
`;

const TabWrap = styled.div`
    margin-bottom: 20px;
`;

const ModalWrap = styled.div`
    @media (max-width: 950px) {
        display: flex;
        flex-direction: column;
    }
`;

export { Root, Header, FilterPanel, RedLabel, LabelWrap, TabWrap, ModalWrap, FilterOverflowWrap, GreenLabel };
