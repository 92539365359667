import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { MemberStatus } from '../../models/member-status.model';
import {
    clearAdoptedInfoCard,
    clearMemberCard,
    loadAdoptedInfoCard,
    loadMemberCard,
} from '../../redux/members.actions';
import { getAdoptedInfoCard, getMemberCard } from '../../redux/members.selectors';
import { Footer, Header, Root, TabWrap } from './member-edit.styles';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useMemberTabs } from '../../../../global/hooks/use-member-tabs';
import { TabBar } from '../../../../global/components/tab-bar/tab-bar';
import AdoptedInfoCardEdit from '../../components/adopted-info-card-edit/adopted-info-card-edit';
import MemberInfoCardEdit from '../../components/member-info-card-edit/member-info-card-edit';
import { useSaveData } from '../../../../global/hooks/use-save-info';
import { useParams } from 'react-router-dom';

const MemberEdit = () => {
    const params = useParams();
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const memberCard = useSelector(getMemberCard);
    const adoptedInfoCard = useSelector(getAdoptedInfoCard);
    const [id, setId] = useState<number | null>(null);
    const [isValid, setIsValid] = useState(false);

    const {
        onTabClick,
        tabs,
        isSelectedMainInfo,
        isSelectedAdoptedInfo,
        setChangeInfo,
        changedMainInfo,
        changedMembership,
    } = useMemberTabs();

    const { saveCallback, saveTrigger } = useSaveData();

    const isSaveCallback = (isSave: boolean) => {
        return isSave;
    };

    const setIsValidMemberMain = (valid: boolean) => {
        setIsValid(valid);
    };

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearMemberCard());
            dispatch(clearAdoptedInfoCard());
            dispatch(loadMemberCard(Number(params?.id)));
            if (showForAdmin) {
                dispatch(loadAdoptedInfoCard(Number(params?.id)));
            }
        }
    }, [params?.id]);

    useEffect(() => {
        if (memberCard) {
            setId(memberCard.id);
        }
    }, [memberCard]);

    const fio = useMemo(() => {
        let result: string = '';
        if (!memberCard) {
            return result;
        }
        if (memberCard.lastName) {
            result = `${memberCard.lastName}`;
        }
        if (memberCard.firstName) {
            result = `${result}${result ? ' ' : ''}${memberCard.firstName}`;
        }
        if (memberCard.patronymic) {
            result = `${result}${result ? ' ' : ''}${memberCard.patronymic}`;
        }
        return result;
    }, [memberCard]);

    const goToList = useCallback(() => {
        goToState('/members');
    }, []);

    const goToCard = useCallback(() => {
        goToState(`/members/${params?.id}`);
    }, []);

    if (!memberCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <InfoItem title="Вы редактируете участника" size={'h1'} />

            <TabBar tabs={tabs} onTabClick={onTabClick} marginBottom={15} />
            <InfoLine>
                <InfoItem title="id" value={id} />
                <InfoItem title="ФИО" value={fio} />
            </InfoLine>
            <TabWrap hide={!isSelectedMainInfo || !showForAdmin}>
                {memberCard && (
                    <MemberInfoCardEdit
                        id={id}
                        isSaveCallback={isSaveCallback}
                        isValidCallback={setIsValidMemberMain}
                        setChangeInfo={setChangeInfo}
                        saveTrigger={saveTrigger}
                        changedMainInfo={changedMainInfo}
                    />
                )}
            </TabWrap>
            <TabWrap hide={!isSelectedAdoptedInfo || !showForAdmin || !adoptedInfoCard}>
                {adoptedInfoCard && (
                    <AdoptedInfoCardEdit
                        id={id}
                        ov={memberCard.status.value !== MemberStatus.OV.value}
                        isSaveCallback={isSaveCallback}
                        setChangeInfo={setChangeInfo}
                        saveTrigger={saveTrigger}
                        changedMembership={changedMembership}
                    />
                )}
            </TabWrap>
            <Footer>
                <Button
                    title="Сохранить"
                    onClick={saveCallback}
                    disabled={!isValid || !(changedMainInfo || changedMembership)}
                    typeColor={'blue'}
                />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default MemberEdit;
