import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AuthService } from '../../../auth/services/AuthService';
import { Menu, MenuItem, Root, Title, TitleWrapper, UserName } from './header.styles';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenMenu } from '../../redux/global/global.selectors';
import { setIsOpenMenu } from '../../redux/global/global.actions';
import { goToState } from '../../helpers/url-parse.helper';
import { Breadcrumbs } from '../breadcrumbs';
import { routesNames } from '../../constants';
import { useLocation } from 'react-router-dom';
import { renderMenu } from './helpers';

export const Header: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement | null>(null);
    const [checkCloseMenu, setCheckCloseMenu] = useState<boolean>(false);
    const userInfo = AuthService.getUserInfo();
    const isOpenMenu = useSelector(getIsOpenMenu);

    useEffect(() => {
        return () => {
            document.removeEventListener('mousedown', closeMenu);
        };
    }, []);

    const toggleMenu = useCallback(() => {
        if (!isOpenMenu) {
            if (checkCloseMenu) {
                setCheckCloseMenu(false);
            } else {
                dispatch(setIsOpenMenu(true));
                document.addEventListener('mousedown', closeMenu);
            }
        } else {
            dispatch(setIsOpenMenu(false));
            document.removeEventListener('mousedown', closeMenu);
        }
    }, [dispatch, setIsOpenMenu, isOpenMenu, setCheckCloseMenu, checkCloseMenu]);

    const menu = renderMenu();

    const closeMenu = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            dispatch(setIsOpenMenu(false));
            setCheckCloseMenu(true);
            window.setTimeout(() => setCheckCloseMenu(false), 500);
        }
    };

    const goToPages = (page: string) => {
        goToState(page);

        dispatch(setIsOpenMenu(false));
    };

    const goToMyCard = useCallback(() => {
        const member = AuthService.getUserInfo()?.member;

        if (!member) {
            return false;
        }

        goToState(`/member/${member.id}`);
    }, []);

    const pathnameRoot = `/${location.pathname.split('/')[1]}`;

    if (!userInfo) {
        return null;
    }

    return (
        <Root>
            <MenuIcon
                className={classNames('menu-icon', { 'is-open': isOpenMenu, })}
                onClick={toggleMenu}
            />

            <TitleWrapper>
                <Title>{routesNames[pathnameRoot]}</Title>

                <Breadcrumbs />
            </TitleWrapper>

            <UserName
                onClick={goToMyCard}
            >
                {userInfo.login}
            </UserName>

            <LogoutIcon
                onClick={AuthService.logOut}
                className="logout-icon"
            />

            {isOpenMenu && (
                <Menu ref={menuRef}>
                    {menu.map((item) => {
                        const { page, name, show, classNameMenu } = item;

                        if (show) {
                            return (
                                <MenuItem className={classNameMenu} onClick={() => goToPages(page)} key={page}>
                                    {name}
                                </MenuItem>
                            );
                        }
                    })}
                </Menu>
            )}
        </Root>
    );
};
