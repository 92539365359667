import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Content, Footer, Root, Title } from './update-link-modal.styles';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Button } from '../../../../global/components/button';
import { ChatInfoList } from '../../models/chat-info-list.model';
import { UpdateLinkParams } from '../../types/update-link-params';
import { updateLinks } from '../../redux/chat-info.actions';
import { loadGroupsForSelect, parseGroupItemToEntityForSelect } from '../../../groups/helpers/api.helpers';
import { loadMinistriesForSelect, parseMinistryItemToEntityForSelect } from '../../../ministries/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';

type UpdateLinkModalProps = {
    chatInfo: ChatInfoList;
    onClose: (withUpdate?: boolean) => void;
};

const UpdateLinkModal = (props: UpdateLinkModalProps) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [isGroup, setIsGroup] = useState<boolean>(true);
    const [group, setGroup] = useState<EntityForSelect | null>(null);
    const [ministry, setMinistry] = useState<EntityForSelect | null>(null);

    useEffect(() => {
        setGroup(props.chatInfo.group ? parseGroupItemToEntityForSelect(props.chatInfo.group) : null);
        setMinistry(props.chatInfo.ministry ? parseMinistryItemToEntityForSelect(props.chatInfo.ministry) : null);
        setIsGroup(!props.chatInfo.ministry);
    }, [props.chatInfo]);

    const isValidForm = useMemo(
        () => (isGroup && group) || (!isGroup && ministry),
        [
            isGroup,
            group,
            ministry,
        ],
    );

    const saveCallback = useCallback(async () => {
        if (!((isGroup && group) || (!isGroup && ministry))) {
            return;
        }
        const params: UpdateLinkParams = {
            groupId: isGroup && group ? group.id : null,
            ministryId: !isGroup && ministry ? ministry.id : null,
        };
        await spinnerRunner(updateLinks(props.chatInfo.id, params, true));
        props.onClose(true);
    }, [
        isGroup,
        group,
        ministry,
        spinnerRunner,
        updateLinks,
        props.onClose,
        props.chatInfo.id,
    ]);

    const cancelCallback = useCallback(() => {
        props.onClose(false);
    }, [props.onClose]);

    const onSelectGroup = useCallback(() => {
        setIsGroup(true);
    }, [setIsGroup]);

    const onSelectMinistry = useCallback(() => {
        setIsGroup(false);
    }, [setIsGroup]);

    const modalStyle: Modal.Styles = useMemo(() => ({
        content: {
            width: '600px',
            height: '350px',
        }
    }), []);

    return (
        <Modal
            isOpen
            style={modalStyle}
        >
            <Root>
                <Title>Обновить ссылки</Title>
                <Content>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isGroup}
                                    onClick={onSelectGroup}
                                />
                            }
                            label="Привязать домашнюю группу"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!isGroup}
                                    onClick={onSelectMinistry}
                                />
                            }
                            label="Привязать служение"
                        />
                    </FormGroup>
                    {
                        !!isGroup &&
                        <SingleSelectEntity
                            label="Домашнаяя группа"
                            value={group}
                            onChange={setGroup}
                            loadOptions={loadGroupsForSelect}
                        />
                    }
                    {
                        !isGroup &&
                        <SingleSelectEntity
                            label="Служение"
                            value={ministry}
                            onChange={setMinistry}
                            loadOptions={loadMinistriesForSelect}
                        />
                    }
                </Content>
                <Footer>
                    <Button
                        title="Закрыть"
                        onClick={cancelCallback}
                        typeColor={'secondary'}
                    />
                    <Button
                        title="Сохранить"
                        onClick={saveCallback}
                        disabled={!isValidForm}
                    />
                </Footer>
            </Root>
        </Modal>
    );
};

export default UpdateLinkModal;
