import styled from 'styled-components/macro';
import { colors, palette } from '../../styles/variables';
import { TypeColor } from './index';
import { Indexable } from '../../types/indexable';

const typeColorsBackground: Indexable<string> = {
    default: colors.buttonStaticBack,
    secondary: colors.BgToolkitNav,
    green: palette.green,
    greenLight: palette.greenLight,
    greenLightActive: palette.greenLightActive,
    grayText: palette.grayText,
    red: colors.errorBorderLabel,
    blue: palette.blue3,
    create: palette.blue10,
};

export const ButtonStyle = styled.div<{ typeColor: TypeColor }>`
    padding: 4px 12px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    background: ${({ typeColor }) => typeColorsBackground[typeColor]};
    color: ${({ typeColor }) => typeColor === 'secondary' ? colors.mainFontColor : colors.white};

    &.is-disabled {
        cursor: default;
        opacity: 0.6;
    }
`;
