import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { MultiValue } from 'react-select';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Footer, Root } from './users-create.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadMembersForSelect } from '../../../members/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { CreateUserParams } from '../../types/create-user-params';
import { createUserCard } from '../../redux/users.actions';
import { AuthService } from '../../../../auth/services/AuthService';
import { UserRoleItem, UserRoleOptions } from '../../models/user-role.model';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { UserPermissionsItem, userPermissionsOptions } from '../../models/user-permissions.model';

const UserCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [login, setLogin] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [role, setRole] = useState<UserRoleItem | null>(null);
    const [permissions, setPermissions] = useState<UserPermissionsItem[]>([]);
    const [member, setMember] = useState<EntityForSelect | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    const isValidForm = useMemo(() => !!login && !!password && !!role, [login, password, role]);

    const onChangePermissions = useCallback(
        (newValue: MultiValue<UserPermissionsItem>) => {
            setPermissions(newValue.map((el) => ({ ...el })));
        },
        [setPermissions],
    );

    const createCallback = useCallback(() => {
        if (!login || !password || !role) {
            return;
        }
        const params: CreateUserParams = {
            login,
            role: role.value,
            password,
            memberId: member?.id || null,
            permissions: permissions.map((el) => el.value),
        };
        spinnerRunner(createUserCard(params));
    }, [login, role, password, member, spinnerRunner, createUserCard, permissions]);

    return (
        <Root>
            <InfoItem title="Вы добавляете пользователя" size={'h1'} />

            <InfoLine>
                <InfoItem title="Логин *" value={<TextInput value={login} onChange={setLogin} />} />
                <InfoItem title="Пароль *" value={<TextInput value={password} onChange={setPassword} />} />
                <InfoItem
                    title="Роль *"
                    value={<Select options={UserRoleOptions} defaultValue={role} onChange={setRole} isClearable />}
                />
            </InfoLine>
            <InfoItem
                title="Доступ к сервисам"
                value={
                    <Select
                        options={userPermissionsOptions}
                        defaultValue={permissions}
                        onChange={onChangePermissions}
                        isClearable
                        isMulti
                    />
                }
            />
            <InfoLine>
                <SingleSelectEntity
                    label="Член церкви"
                    value={member}
                    onChange={setMember}
                    loadOptions={loadMembersForSelect}
                />
            </InfoLine>
            <Footer>
                <Button title="Сохранить" onClick={createCallback} disabled={!isValidForm} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default UserCreate;
