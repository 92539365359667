import { AuthService } from '../../../auth/services/AuthService';
import classNames from 'classnames';
import { isModule } from '../../helpers/url-parse.helper';
import { allPages, routesNames } from '../../constants';

export const renderMenu = () => {
    const showForAdmin = AuthService.availableForAdmin();
    const showOV = showForAdmin || AuthService.hasOVPermission();
    const showService = showForAdmin || AuthService.hasServicePermission();
    // const showMessages = !!showForAdmin || AuthService.hasMessagesPermission();

    return [
        {
            page: allPages.members,
            show: true,
            name: routesNames[allPages.members],
            classNameMenu: classNames({
                'is-select': isModule('member'),
            }),
        },
        {
            page: allPages.family,
            show: showForAdmin,
            name: routesNames[allPages.family],
            classNameMenu: classNames({
                'is-select': isModule('family'),
            }),
        },
        {
            page: allPages.ministries,
            show: showForAdmin,
            name: routesNames[allPages.ministries],
            classNameMenu: classNames({
                'is-select': isModule('ministries'),
            }),
        },
        {
            page: allPages.groups,
            show: showForAdmin,
            name: routesNames[allPages.groups],
            classNameMenu: classNames({
                'is-select': isModule('group'),
            }),
        },
        {
            page: allPages.cohorts,
            show: showOV,
            name: routesNames[allPages.cohorts],
            classNameMenu: classNames({
                'is-select': isModule('cohorts'),
            }),
        },
        {
            page: allPages.serviceSettings,
            show: showService,
            name: routesNames[allPages.serviceSettings],
            classNameMenu: classNames({
                'is-select': isModule('service-settings'),
            }),
        },
        {
            page: allPages.resources,
            show: showForAdmin,
            name: routesNames[allPages.resources],
            classNameMenu: classNames({
                'is-select': isModule('resource'),
            }),
        },
        {
            page: allPages.chatInfo,
            show: showForAdmin,
            name: routesNames[allPages.chatInfo],
            classNameMenu: classNames({
                'is-select': isModule('chat-info'),
            }),
        },
        {
            page: allPages.users,
            show: showForAdmin,
            name: routesNames[allPages.users],
            classNameMenu: classNames({
                'is-select': isModule('user'),
            }),
        },
    ];
};
