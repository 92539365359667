import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import {
    clearAdoptedInfoCard,
    clearMemberCard,
    deleteAvatar,
    deleteMemberCard,
    loadAdoptedInfoCard,
    loadMemberCard,
    uploadNewAvatar,
} from '../../redux/members.actions';
import { getMemberCard } from '../../redux/members.selectors';
import { MembersRepository } from '../../repositories/members.repository';
import { Avatar, AvatarActionWrap, FileUploadInput, FileUploadLabel, Header, Root } from './member-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import CreateFamilyModal from '../../../family/components/create-family-modal/create-family-modal';
import { AuthService } from '../../../../auth/services/AuthService';
import { MemberItem } from '../../../family/pages/card/family-card.styles';
import { toast } from 'react-toastify';
import { LinkA } from '../../../../global/theme/global.styles';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import PastorNoteModal from '../../components/pastor-note-modal/pastor-note-modal';
import { GreenLabel, LabelWrap, RedLabel } from '../list/members-list.styles';
import classNames from 'classnames';
import { MemberStatus } from '../../models/member-status.model';
import { Checkbox, FormControlLabel, TableContainer } from '@mui/material';
import PaymentInfo from '../../components/payment-info/payment-info';
import { PaymentsRepository } from '../../repositories/payments.repository';
import { useMemberTabs } from '../../../../global/hooks/use-member-tabs';
import { TabBar } from '../../../../global/components/tab-bar/tab-bar';
import AdoptedInfoCard from '../../components/adopted-info-card/adopted-info-card';
import { useParams } from 'react-router-dom';

const MemberCard = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const memberCard = useSelector(getMemberCard);
    const [id, setId] = useState<number | null>(null);
    const [tempAboutMe, setTempAboutMe] = useState<string | null>(null);
    const [lastSaveAboutMe, setLastSaveAboutMe] = useState<string | null>(null);
    const [openCreateFamilyModal, setOpenCreateFamilyModal] = useState<boolean>(false);
    const [openPastorNoteModal, setOpenPastorNoteModal] = useState<boolean>(false);
    const [donatedRecently, setDonatedRecently] = useState<boolean>(false);
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const showForPastor = useMemo(() => AuthService.hasPastorPermission(), [AuthService.hasPastorPermission]);
    const [avatar, setAvatar] = useState<string | null>(null);
    const { onTabClick, tabs, isSelectedMainInfo, isSelectedAdoptedInfo } = useMemberTabs();
    const isAdminView = isSelectedAdoptedInfo && !!showForAdmin;

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearMemberCard());
            dispatch(clearAdoptedInfoCard());
            setId(Number(params?.id));
            loadAllCardInfo(Number(params?.id));
            updateAvatar();
        }
    }, [params?.id]);

    useEffect(() => {
        setTempAboutMe(memberCard?.aboutMe || null);
        setLastSaveAboutMe(memberCard?.aboutMe || null);
    }, [memberCard]);

    const debounceAboutMeText = useDebounce(tempAboutMe);

    const loadAllCardInfo = useCallback(
        (id: number) => {
            spinnerRunner(loadMemberCard(id));
            if (showForAdmin) {
                spinnerRunner(loadAdoptedInfoCard(id));
            }
            PaymentsRepository.getDonatedRecently(id).then((res) => setDonatedRecently(res));
        },
        [showForAdmin],
    );

    const updateAvatar = useCallback(() => {
        MembersRepository.getAvatar(Number(params?.id)).then((res: string | null) => {
            setAvatar(res);
        });
    }, []);

    const isDiscipline = useMemo(
        () =>
            [MemberStatus.ON_REMARK.value, MemberStatus.EXCOMMUNICATED.value].some(
                (v) => memberCard?.status.value === v,
            ),
        [memberCard?.status.value],
    );

    const updateTempAboutMe = useCallback(
        (value: string | null) => {
            if (value && value.length > 1000) {
                setTempAboutMe(tempAboutMe);
                toast.error('Допустимая длина текста: 1000 символов');
            } else {
                setTempAboutMe(value);
            }
        },
        [tempAboutMe],
    );

    useEffect(() => {
        if (debounceAboutMeText === lastSaveAboutMe) {
            return;
        }
        MembersRepository.updateAboutMe(Number(params?.id), debounceAboutMeText);
        setLastSaveAboutMe(debounceAboutMeText);
    }, [debounceAboutMeText]);

    const openCreateFamilyModalCallback = useCallback(() => setOpenCreateFamilyModal(true), [setOpenCreateFamilyModal]);
    const openPastorNoteModalCallback = useCallback(() => setOpenPastorNoteModal(true), [setOpenPastorNoteModal]);
    const closeCreateFamilyModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenCreateFamilyModal(false);
            if (withUpdate) {
                loadAllCardInfo(Number(params?.id));
            }
        },
        [setOpenCreateFamilyModal, params?.id, spinnerRunner, loadAllCardInfo],
    );
    const closePastorNoteModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenPastorNoteModal(false);
            if (withUpdate) {
                loadAllCardInfo(Number(params?.id));
            }
        },
        [setOpenPastorNoteModal, loadAllCardInfo, params?.id, spinnerRunner],
    );
    const goToList = useCallback(() => {
        goToState('/members');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/members/${params?.id}/edit`);
    }, [params?.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteMemberCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id]);

    const fio = useMemo(() => {
        let result: string = '';
        if (!memberCard) {
            return result;
        }
        if (memberCard.lastName) {
            result = `${memberCard.lastName}`;
        }
        if (memberCard.firstName) {
            result = `${result}${result ? ' ' : ''}${memberCard.firstName}`;
        }
        if (memberCard.patronymic) {
            result = `${result}${result ? ' ' : ''}${memberCard.patronymic}`;
        }
        return result;
    }, [memberCard]);

    const handleAvatarToUpload = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.currentTarget.files;
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
            if (!allowedExtensions.exec(e.currentTarget.value)) {
                toast.error('Недопустимый формат файла (допустимые форматы: .jpg .jpeg .png .gif)');
                e.currentTarget.value = '';
                return;
            }

            if (filesList && id) {
                if (filesList[0].size > 1024 * 1024 * 2) {
                    toast.error('Слишком большой файл (необходимо не больше 2МБ)');
                    e.currentTarget.value = '';
                    return;
                }

                await spinnerRunner(uploadNewAvatar(id, filesList[0]));
                dispatch(clearMemberCard());
                loadAllCardInfo(id);
                updateAvatar();
            }
        },
        [dispatch, id, uploadNewAvatar, loadAllCardInfo],
    );

    const deleteAvatarCallback = useCallback(async () => {
        if (!id) {
            return;
        }

        confirmAlert({
            title: 'Удаление фото',
            message: 'Вы уверены, что хотите удалить это фото?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteAvatar(id));
                        dispatch(clearMemberCard());
                        loadAllCardInfo(id);
                        updateAvatar();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [dispatch, id, deleteAvatar, confirmAlert, loadAllCardInfo]);

    if (!memberCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {showForAdmin && (
                    <>
                        <Button title="Редактировать" onClick={goToEdit} />
                        <Button title="Удалить" onClick={goToDelete} typeColor={'red'} />
                    </>
                )}
                {(showForAdmin || showForPastor) && (
                    <Button
                        title="Заметки"
                        onClick={openPastorNoteModalCallback}
                        typeColor={memberCard.pastorNote ? 'greenLightActive' : 'greenLight'}
                    />
                )}
            </Header>
            <TabBar tabs={tabs} onTabClick={onTabClick} marginBottom={15} />
            <TableContainer sx={{ maxHeight: 'calc(100vh - 150px);' }}>
                {avatar && isSelectedMainInfo && <Avatar src={`data:image/png;base64,${avatar}`} />}
                {isSelectedMainInfo && (
                    <>
                        {showForAdmin && (
                            <AvatarActionWrap>
                                <FileUploadLabel htmlFor="avatar-uploading">
                                    <Button title={avatar ? 'Обновить фото' : 'Загрузить фото'} typeColor={'grayText'} />
                                </FileUploadLabel>
                                {!!avatar && <Button title="Удалить фото" onClick={deleteAvatarCallback} typeColor={'red'} />}
                                <FileUploadInput
                                    type="file"
                                    id="avatar-uploading"
                                    multiple={false}
                                    onChange={handleAvatarToUpload}
                                />
                            </AvatarActionWrap>
                        )}
                        <InfoLine>
                            <InfoItem title="ФИО" value={fio} hideIsEmpty />
                            <InfoItem title="Телефон" value={memberCard.phone} hideIsEmpty />
                            {(!!memberCard.phone || !!memberCard.username) && (
                                <InfoItem
                                    title="Телеграм"
                                    value={
                                        <LinkA
                                            href={`http://t.me/${memberCard.username || memberCard.phone}`}
                                            target="_blank"
                                        >
                                            телеграм
                                        </LinkA>
                                    }
                                    hideIsEmpty
                                />
                            )}
                        </InfoLine>
                        {!!showForAdmin && !!memberCard.notCongratulation && (
                            <FormControlLabel
                                className="filter-item filter-item-checkbox"
                                control={<Checkbox checked />}
                                label="Не поздравлять с днём рождения"
                            />
                        )}
                        <InfoLine>
                            <InfoItem title="Пол" value={memberCard.sex?.label || ''} hideIsEmpty />
                            <InfoItem
                                title="Дата рождения"
                                value={memberCard.birthDay}
                                dateFormat={showForAdmin || showForPastor ? 'DD-MM-YYYY' : 'DD.MM'}
                                hideIsEmpty
                            />
                            {!!showForLeader && (
                                <InfoItem
                                    title="Семья"
                                    hideIsEmpty
                                    value={
                                        <div>
                                            {memberCard.family && (
                                                <a href={`#/family/${memberCard.family.id}`}>
                                                    {memberCard.family.name}
                                                </a>
                                            )}

                                            {!memberCard.family && !!showForAdmin && (
                                                <AddIcon
                                                    className="min-action-icon"
                                                    onClick={openCreateFamilyModalCallback}
                                                />
                                            )}
                                        </div>
                                    }
                                />
                            )}
                        </InfoLine>
                        {(!!showForAdmin || memberCard.isMyCard) && (
                            <InfoLine>
                                <InfoItem title="Год покаяния" hideIsEmpty value={memberCard.repentanceYear} />
                                <InfoItem title="Место покаяния" hideIsEmpty value={memberCard.repentancePlace} />
                                <InfoItem title="Год крещения" hideIsEmpty value={memberCard.baptismYear} />
                            </InfoLine>
                        )}
                        {(!!showForAdmin || memberCard.isMyCard) && (
                            <InfoLine>
                                <InfoItem
                                    title="Статус в церкви"
                                    hideIsEmpty
                                    value={
                                        <LabelWrap>
                                            <RedLabel
                                                className={classNames({
                                                    gray: memberCard.status.value !== MemberStatus.EXCOMMUNICATED.value,
                                                })}
                                            >
                                                {memberCard.status.label}
                                            </RedLabel>
                                            {!!memberCard.outsideGroups && <RedLabel>Вне малой группы</RedLabel>}
                                            {!!memberCard.outsideMinistries && <RedLabel>Вне служения</RedLabel>}
                                            {!!memberCard.confirmationIsFalse && (
                                                <RedLabel>Не подтвердил членство</RedLabel>
                                            )}
                                            {(memberCard.status.value === MemberStatus.RBC.value ||
                                                memberCard.status.value === MemberStatus.ON_REMARK.value) &&
                                                !donatedRecently && <RedLabel>Проблемы с финансами</RedLabel>}
                                            {!!memberCard.rbcBaptism && <GreenLabel>Крещён в РБЦ</GreenLabel>}
                                        </LabelWrap>
                                    }
                                />
                                <InfoItem
                                    title="Дата рассказывания свидетельства"
                                    value={memberCard.witnessesDate}
                                    dateFormat="DD-MM-YYYY"
                                    hideIsEmpty
                                />
                                <InfoItem
                                    title="Дата принятия в члены церкви"
                                    value={memberCard.adoptedDate}
                                    dateFormat="DD-MM-YYYY"
                                    hideIsEmpty
                                />
                                <InfoItem
                                    title="Дата отбытия"
                                    value={memberCard.disposalDate}
                                    dateFormat="DD-MM-YYYY"
                                    hideIsEmpty
                                />
                            </InfoLine>
                        )}
                        {(!!showForAdmin || memberCard.isMyCard) && (
                            <InfoLine>
                                {!!isDiscipline && (
                                    <InfoLine>
                                        <InfoItem
                                            title="Дата дисциплины"
                                            value={memberCard.disciplineDate}
                                            dateFormat="DD-MM-YYYY"
                                        />
                                    </InfoLine>
                                )}
                                {!!isDiscipline && (
                                    <InfoLine>
                                        <InfoItem
                                            title="Причина дисциплины"
                                            hideIsEmpty
                                            value={memberCard.disciplineReason}
                                        />
                                    </InfoLine>
                                )}
                            </InfoLine>
                        )}
                        {!memberCard.isMyCard && <InfoItem title="О себе" hideIsEmpty value={memberCard.aboutMe} />}
                        {!!memberCard.isMyCard && (
                            <InfoItem
                                title="О себе"
                                value={
                                    <TextInput
                                        value={tempAboutMe}
                                        onChange={updateTempAboutMe}
                                        asTextarea
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                }
                                valueStyle={{
                                    width: '100%',
                                }}
                            />
                        )}
                        {!!showForLeader &&
                            memberCard.ministriesLead.map((el, i) => (
                                <InfoItem
                                    key={i}
                                    title="Лидер служения"
                                    value={
                                        <div>
                                            <MemberItem key={el.id}>
                                                <a href={`#/ministries/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        </div>
                                    }
                                />
                            ))}
                        {!!showForLeader &&
                            memberCard.ministriesAssist.map((el, i) => (
                                <InfoItem
                                    key={i}
                                    title="Помощник в служении"
                                    value={
                                        <div>
                                            <MemberItem key={el.id}>
                                                <a href={`#/ministries/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        </div>
                                    }
                                />
                            ))}
                        {!!showForLeader &&
                            memberCard.groupLead.map((el, i) => (
                                <InfoItem
                                    key={i}
                                    title="Лидер группы"
                                    value={
                                        <div>
                                            <MemberItem key={el.id}>
                                                <a href={`#/groups/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        </div>
                                    }
                                />
                            ))}
                        {!!showForLeader &&
                            memberCard.groupAssist.map((el, i) => (
                                <InfoItem
                                    key={i}
                                    title="Помощник в группе"
                                    value={
                                        <div>
                                            <MemberItem key={el.id}>
                                                <a href={`#/groups/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        </div>
                                    }
                                />
                            ))}
                        {!!showForLeader && !!memberCard.groups.length && (
                            <InfoItem
                                title="Группы"
                                value={
                                    <div>
                                        {memberCard.groups.map((el) => (
                                            <MemberItem key={el.id}>
                                                <a href={`#/groups/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        ))}
                                    </div>
                                }
                            />
                        )}
                        {!!showForLeader && !!memberCard.ministries.length && (
                            <InfoItem
                                title="Служения"
                                value={
                                    <div>
                                        {memberCard.ministries.map((el) => (
                                            <MemberItem key={el.id}>
                                                <a href={`#/ministries/${el.id}`}>{el.name}</a>
                                            </MemberItem>
                                        ))}
                                    </div>
                                }
                            />
                        )}
                        {!!showForAdmin && !!memberCard.user && (
                            <InfoItem
                                title="Учётная запись"
                                value={
                                    <div>
                                        <MemberItem>
                                            <a href={`#/users/${memberCard.user.id}`}>{memberCard.user.login}</a>
                                        </MemberItem>
                                    </div>
                                }
                            />
                        )}
                        {!!openCreateFamilyModal && !!showForAdmin && (
                            <CreateFamilyModal member={memberCard} onClose={closeCreateFamilyModalCallback} />
                        )}
                        {!!openPastorNoteModal && (!!showForAdmin || !!showForPastor) && (
                            <PastorNoteModal member={memberCard} onClose={closePastorNoteModalCallback} />
                        )}
                        {!!showForAdmin && !!memberCard.completeAu && (
                            <FormControlLabel
                                className="filter-item filter-item-checkbox"
                                control={<Checkbox checked />}
                                label="Прошёл АУ"
                            />
                        )}
                        {!!showForAdmin &&
                            (memberCard.status.value === MemberStatus.RBC.value ||
                                memberCard.status.value === MemberStatus.ON_REMARK.value) && (
                            <InfoItem
                                title="Статистика пожертвований"
                                value={
                                    <PaymentInfo
                                        memberId={memberCard.id}
                                        witnessesDate={memberCard.witnessesDate}
                                    />
                                }
                            />
                        )}
                    </>
                )}

                {isAdminView &&
                    (memberCard.status.value === MemberStatus.OV.value ? <AdoptedInfoCard /> : <AdoptedInfoCard ov />)}
            </TableContainer>
            <BottomPadding />
        </Root>
    );
};

export default MemberCard;
