import styled from 'styled-components/macro';
import { colors, palette } from '../../../../global/styles/variables';

export const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
`;

export const HomeListItem = styled.div`
    width: 25%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0 20px;
`;

export const HomeListItemIn = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 1px solid ${palette.blue};

    &:hover {
        background: ${colors.buttonHoverBack};
        color: ${colors.white};
    }
`;
