import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

export const BreadcrumbsRoot = styled.div`
    margin-right: 30px;
    display: flex;
    align-items: center;
`;

export const NavLinkRoot = styled(NavLink)`
    margin-right: 5px;
`;

export const NavLinkSeparator = styled('div')`
    margin-right: 5px;
`;
