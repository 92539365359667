import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { MemberSexItem, sexOptions } from '../../models/member-sex.model';
import { MemberStatusItem, statusOptions } from '../../models/member-status.model';
import { Footer, Root } from './member-create.styles';
import { CreateMemberParams } from '../../types/create-member-params';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { createMemberCard } from '../../redux/members.actions';
import { parseDateForApi } from '../../../../global/helpers/date.helper';
import { AuthService } from '../../../../auth/services/AuthService';
import { TextInputMask } from '../../../../global/components/form-controls/text-input-mask/text-input-mask';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Calendar } from 'primereact/calendar';
import { useEventValue } from '../../../../global/hooks/use-event-value';
import { Checkbox, FormControlLabel } from '@mui/material';

const MemberCreate = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [patronymic, setPatronymic] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [sex, setSex] = useState<MemberSexItem | null>(null);
    const [status, setStatus] = useState<MemberStatusItem | null>(null);
    const [birthDay, setBirthDay] = useState<Date | null>(null);
    const [birthPlace, setBirthPlace] = useState<string | null>(null);
    const [skills, setSkills] = useState<string | null>(null);
    const [repentanceYear, setRepentanceYear] = useState<string | null>(null);
    const [repentancePlace, setRepentancePlace] = useState<string | null>(null);
    const [baptismYear, setBaptismYear] = useState<string | null>(null);
    const [witnessesDate, setWitnessesDate] = useState<Date | null>(null);
    const [adoptedDate, setAdoptedDate] = useState<Date | null>(null);
    const [disposalDate, setDisposalDate] = useState<Date | null>(null);
    const [externalId] = useState<string | null>(null);
    const [rbcBaptism, setRbcBaptism] = useState<boolean>(false);

    const handleBirthDateChange = useEventValue(setBirthDay);
    const handleWitnessesDateChange = useEventValue(setWitnessesDate);
    const handleAdoptedDateChange = useEventValue(setAdoptedDate);
    const handleDisposalDateChange = useEventValue(setDisposalDate);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    const isValidForm = useMemo(
        () => !!firstName && !!lastName && !!phone && phone.indexOf('_') === -1 && !!status && !!sex,
        [firstName, lastName, phone, status, sex],
    );

    const createCallback = useCallback(() => {
        if (!firstName || !lastName || !phone || !status || !sex || phone.indexOf('_') !== -1) {
            return;
        }
        const params: CreateMemberParams = {
            firstName,
            lastName,
            patronymic,
            phone,
            status: status.value,
            sex: sex.value,
            bDay: parseDateForApi(birthDay),
            birthPlace,
            skills,
            repentanceYear,
            repentancePlace,
            baptismYear,
            witnessesDate: parseDateForApi(witnessesDate),
            adoptedDate: parseDateForApi(adoptedDate),
            disposalDate: parseDateForApi(disposalDate),
            disciplineDate: null,
            disciplineReason: null,
            externalId: externalId ? Number(externalId) : null,
            notCongratulation: false,
            completeAu: false,
            rbcBaptism,
        };
        spinnerRunner(createMemberCard(params));
    }, [
        firstName,
        lastName,
        patronymic,
        phone,
        status,
        sex,
        birthDay,
        birthPlace,
        skills,
        repentanceYear,
        repentancePlace,
        baptismYear,
        witnessesDate,
        adoptedDate,
        disposalDate,
        externalId,
        rbcBaptism,
        spinnerRunner,
        createMemberCard,
    ]);

    const toggleRbcBatism = useCallback(() => {
        setRbcBaptism(!rbcBaptism);
    }, [rbcBaptism]);

    return (
        <Root>
            <InfoItem title="Вы добавляете участника" size={'h1'} />

            <InfoLine>
                <InfoItem title="Фамилия *" value={<TextInput value={lastName} onChange={setLastName} />} />
                <InfoItem title="Имя *" value={<TextInput value={firstName} onChange={setFirstName} />} />
                <InfoItem title="Отчество" value={<TextInput value={patronymic} onChange={setPatronymic} />} />
                {/* <InfoItem
                    title="Внешний id (из гугл.таблицы)"
                    value={<TextInput
                        value={externalId}
                        onChange={setExternalId}
                    />}
                /> */}
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Телефон *"
                    value={<TextInputMask value={phone} mask="+99999999999" onChange={setPhone} />}
                />
                <InfoItem
                    title="Церковный статус *"
                    value={<Select options={statusOptions} defaultValue={status} onChange={setStatus} isClearable />}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Пол *"
                    value={<Select options={sexOptions} defaultValue={sex} onChange={setSex} isClearable />}
                />
                <InfoItem
                    title="Дата рождения"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={birthDay}
                            onChange={handleBirthDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem title="Место рождения" value={<TextInput value={birthPlace} onChange={setBirthPlace} />} />
            </InfoLine>
            <InfoLine>
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={<Checkbox checked={rbcBaptism} onClick={toggleRbcBatism} />}
                    label="Крещён в РБЦ"
                />
            </InfoLine>
            <InfoItem title="Способности" value={<TextInput value={skills} onChange={setSkills} asTextarea />} />
            <InfoLine>
                <InfoItem
                    title="Год покаяния"
                    value={<TextInput value={repentanceYear} onChange={setRepentanceYear} />}
                />
                <InfoItem
                    title="Место покаяния"
                    value={<TextInput value={repentancePlace} onChange={setRepentancePlace} />}
                />
                <InfoItem title="Год крещения" value={<TextInput value={baptismYear} onChange={setBaptismYear} />} />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Дата рассказывания свидетельства"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={witnessesDate}
                            onChange={handleWitnessesDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата принятия в члены церкви"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={adoptedDate}
                            onChange={handleAdoptedDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
                <InfoItem
                    title="Дата отбытия"
                    value={
                        <Calendar
                            locale={'ru'}
                            className="local-datapicker"
                            value={disposalDate}
                            onChange={handleDisposalDateChange}
                            dateFormat={'dd.mm.yy'}
                        />
                    }
                />
            </InfoLine>
            <Footer>
                <Button title="Сохранить" onClick={createCallback} disabled={!isValidForm} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default MemberCreate;
