import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpinner } from '../../../../../global/hooks/use-spinner';
import { BottomPadding } from '../../../../../global/components/main-wrap/main-wrap.styles';
import { getOVRegistrationList } from '../../../redux/registrsation/ov-registration.selectors';
import { updateOVRegistrations } from '../../../redux/registrsation/ov-registration.actions';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { OVRegistration } from '../../../models/registration/ov-registration.model';
import { ActionWrap, AnswersButton, Root } from './ov-registration-list.styles';
import { MemberItem } from '../../../../family/pages/card/family-card.styles';
import OVAnswerListModal from '../../answer/answer-list-modal/answer-list-modal';
import { LabelWrap } from '../../../../members/pages/list/members-list.styles';

type OVRegistrationItemProps = {
    item: OVRegistration;
};

const OVRegistrationItem = (props: OVRegistrationItemProps) => {
    const [openAnswerListModal, setOpenAnswerListModal] = useState<boolean>(false);

    const toggleAnswerListModal = useCallback(() => {
        setOpenAnswerListModal((prev) => !prev);
    }, []);

    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={toggleAnswerListModal}>
            <TableCell align="left">{props.item.id}</TableCell>
            <TableCell align="left">
                <MemberItem key={props.item.profile.id}>
                    <a href={`#/members/${props.item.profile.id}`}>{props.item.profile.name}</a>
                </MemberItem>
            </TableCell>
            <TableCell align="left">
                <ActionWrap>
                    <LabelWrap>
                        <AnswersButton onClick={toggleAnswerListModal} status={props.item.status}>
                            Анкета
                        </AnswersButton>
                    </LabelWrap>
                    {/* <EditIcon onClick={goToEdit} className="action-icon" />
                    {
                        !props.item.isRegistrationOpen &&
                        <DeleteIcon onClick={goToDelete} className="action-icon" />
                    } */}
                </ActionWrap>
            </TableCell>
            {!!openAnswerListModal && <OVAnswerListModal registration={props.item} onClose={toggleAnswerListModal} />}
        </TableRow>
    );
};

type OVRegistrationProp = {
    cohortId?: number;
};

const OVRegistrationList = (props: OVRegistrationProp) => {
    const dispatch = useDispatch();

    const spinnerRunner = useSpinner(dispatch);
    const ovRegistrationList = useSelector(getOVRegistrationList);

    useEffect(() => {
        spinnerRunner(
            updateOVRegistrations(
                {
                    pageNo: 0,
                    pageSize: 1000,
                },
                {
                    cohortId: props.cohortId,
                },
            ),
        );
    }, [props.cohortId]);

    return (
        <Root>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Профиль</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ovRegistrationList.map((registration, index) => (
                                <OVRegistrationItem item={registration} key={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <BottomPadding />
        </Root>
    );
};

export default OVRegistrationList;
