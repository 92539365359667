import React, { useCallback, useEffect, useMemo } from 'react';
import { Root } from './groups-list-on-map.styles';
import { AuthService } from '../../../../auth/services/AuthService';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { InfoItem } from '../../../../global/components/info-item';
import MapComponent, { MapObject } from '../../../../global/components/map/map';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroups } from '../../redux/groups.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { makeGetIsShowLocalSpinner } from '../../../../global/redux/global/global.selectors';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { updateAllGroups } from '../../redux/groups.actions';
import { getNumberCoordByString } from '../../../../global/helpers/map.helper';
import { getLeadersNamesStringHelper } from '../../helpers/groups-on-map.helpers';

export const GroupsOnMap = () => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.allGroups));
    const allGroups = useSelector(getAllGroups);

    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }

        localSpinnerRunner(updateAllGroups(), LocalSpinnerKeysEnum.allGroups);
    }, []);

    const handleMarkerClick = useCallback((id: number) => {
        goToState(`/groups/${id}`);
    }, []);

    const mapObjects = useMemo<MapObject[]>(() => {
        return allGroups.map<MapObject>((group) => ({
            coords: getNumberCoordByString(group.coords),
            onClickCallback: () => handleMarkerClick(group.id),
            tooltipData: {
                description: `${getLeadersNamesStringHelper(group) || ''}<br>${(group.day || null)?.label}`,
                header: group?.name || '',
            },
        }));
    }, [allGroups, handleMarkerClick]);

    return (
        <Root>
            <InfoItem title="Карта всех групп" size={'h1'} />

            {!!mapObjects.length && (
                <InfoItem
                    title=""
                    value={<MapComponent objects={mapObjects} disable size={{ width: 99, height: 100 }} />}
                />
            )}
            <LocalSpinner loading={isShowSpinner} size={30} />
        </Root>
    );
};
