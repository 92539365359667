import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../global/helpers/actions';
import { goToState } from '../../../global/helpers/url-parse.helper';
import { GlobalActionsEnum } from '../../../global/redux/global/global.types';
import { MembersRepository } from '../../members/repositories/members.repository';
import { UserCard } from '../models/user-card.model';
import { UsersRepository } from '../repositories/users.repository';
import { CreateUserParams } from '../types/create-user-params';
import { GetUserListBodyParams } from '../types/get-user-list-body-params';
import { GetUserListQueryParams } from '../types/get-user-list-query-params';
import { UpdateUsersAction, UsersActionsEnum } from './users.types';

const updateUsers = (queryParams: GetUserListQueryParams, bodyData: GetUserListBodyParams) =>
    baseAsyncActionCreator<UpdateUsersAction>(async () => {
        const result = await UsersRepository.getList(queryParams, bodyData);
        return [
            UsersActionsEnum.updateUsers,
            {
                users: result.items,
                totalCount: result.totalCount,
            },
        ];
    });

const clearUserCard = () =>
    baseAsyncActionCreator<UserCard | null>(async () => {
        return [UsersActionsEnum.updateUserCard, null];
    });

const loadUserCard = (id: number) =>
    baseAsyncActionCreator<UserCard | null>(async () => {
        const result = await UsersRepository.getCard(id);
        return [UsersActionsEnum.updateUserCard, result];
    });

const createUserCard = (params: CreateUserParams) =>
    baseAsyncActionCreator<string>(async () => {
        await UsersRepository.create(params);
        toast.success('Запись успешно добавлена!');
        goToState('/users');
        return [GlobalActionsEnum.empty, ''];
    });

const updateUserCard = (id: number, params: CreateUserParams) =>
    baseAsyncActionCreator<string>(async () => {
        await UsersRepository.update(id, params);
        toast.success('Запись успешно обновлена!');
        goToState(`/users/${id}`);
        return [GlobalActionsEnum.empty, ''];
    });

const deleteUserCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await UsersRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

const createList = () =>
    baseAsyncActionCreator<void>(async () => {
        await MembersRepository.createUsers();
        return null;
    });

export { updateUsers, clearUserCard, loadUserCard, createUserCard, updateUserCard, deleteUserCard, createList };
