import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addParamsToUrl, goToState } from '../../../../global/helpers/url-parse.helper';
import { FilterPanel, Root } from './chat-info-list.styles';

import Paper from '@mui/material/Paper';
import LinkIcon from '@mui/icons-material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { BottomPadding, LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../global/components/button';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { ChatInfoList as ChatInfoListModel } from '../../models/chat-info-list.model';
import { GetChatInfoListQueryParams } from '../../types/get-chat-info-list-query-params';
import { GetChatInfoListBodyParams } from '../../types/get-chat-info-list-body-params';
import { getChatInfoList, getChatInfoTotalCount } from '../../redux/chat-info.selectors';
import { updateChatInfoList } from '../../redux/chat-info.actions';
import UpdateLinkModal from '../../components/update-link-modal/update-link-modal';
import { AuthService } from '../../../../auth/services/AuthService';
import { LinkA } from '../../../../global/theme/global.styles';
import { Checkbox, FormControlLabel } from '@mui/material';

type ChatInfoItemProps = {
    item: ChatInfoListModel;
    queryParams: GetChatInfoListQueryParams;
    bodyParams: GetChatInfoListBodyParams;
};

const ChatInfoItem = (props: ChatInfoItemProps) => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const [openUpdateLinkModal, setOpenUpdateLinkModal] = useState<boolean>(false);
    const goToCard = useCallback(() => {
        goToState(`/chat-info/${props.item.id}`);
    }, [props.item.id]);

    const openUpdateLinkModalCallback = useCallback(() => {
        setOpenUpdateLinkModal(true);
    }, [setOpenUpdateLinkModal]);

    const closeUpdateLinkModalCallback = useCallback(
        (withUpdate?: boolean) => {
            setOpenUpdateLinkModal(false);
            if (withUpdate) {
                localSpinnerRunner(
                    updateChatInfoList(props.queryParams, props.bodyParams),
                    LocalSpinnerKeysEnum.chatInfoList,
                );
            }
        },
        [setOpenUpdateLinkModal],
    );

    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
            <TableCell align="left">{props.item.id}</TableCell>
            <TableCell align="left">{props.item.chatId}</TableCell>
            <TableCell align="left">
                <LinkA href={`#/chat-info/${props.item.id}`}>{props.item.chatName}</LinkA>
            </TableCell>
            <TableCell align="left">
                {props.item.group && (
                    <>
                        <a href={`#/groups/${props.item.group.id}`}>{props.item.group.name}</a>
                    </>
                )}
            </TableCell>
            <TableCell align="left">
                {props.item.ministry && (
                    <>
                        <a href={`#/ministries/${props.item.ministry.id}`}>{props.item.ministry.name}</a>
                    </>
                )}
            </TableCell>
            <TableCell align="left">
                <LinkIcon className="min-action-icon" onClick={openUpdateLinkModalCallback} />
            </TableCell>
            {!!openUpdateLinkModal && <UpdateLinkModal chatInfo={props.item} onClose={closeUpdateLinkModalCallback} />}
        </TableRow>
    );
};

const ChatInfoList = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const pageNo = useSelector(getLocationSearchParamByKey('pageNo'));
    const pageSize = useSelector(getLocationSearchParamByKey('pageSize'));
    const filterHasNotLink = useSelector(getLocationSearchParamByKey('hasNotLink'));
    const chatInfoList = useSelector(getChatInfoList);
    const chatInfoTotalCount = useSelector(getChatInfoTotalCount);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.chatInfoList));
    const searchString = useSelector(getLocationSearchParamByKey('searchString'));
    const debounceSearchInputText = useDebounce(searchString);

    const queryParams: GetChatInfoListQueryParams = useMemo(
        () => ({
            pageNo: pageNo ? Number(pageNo) : 0,
            pageSize: pageSize ? Number(pageSize) : 10,
        }),
        [pageNo, pageSize],
    );

    const bodyParams: GetChatInfoListBodyParams = useMemo(
        () => ({
            searchString: debounceSearchInputText || '',
            hasNotLink: filterHasNotLink === 'true',
        }),
        [debounceSearchInputText, filterHasNotLink],
    );

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        localSpinnerRunner(updateChatInfoList(queryParams, bodyParams), LocalSpinnerKeysEnum.chatInfoList);
    }, [queryParams, bodyParams]);

    const pageNoValue = useMemo(
        () => (chatInfoTotalCount ? (pageNo === null ? 0 : Number(pageNo)) : 0),
        [pageNo, chatInfoTotalCount],
    );
    const pageSizeValue = useMemo(
        () => (chatInfoTotalCount ? (pageSize === null ? 10 : Number(pageSize)) : 0),
        [pageSize, chatInfoTotalCount],
    );
    const handleChangePage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: page,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: event.target.value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const updateSearchString = useCallback(
        (value: string | null) => {
            addParamsToUrl([
                {
                    key: 'searchString',
                    value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const hasNotLinkValue = useMemo(() => filterHasNotLink === 'true', [filterHasNotLink]);

    const toggleHasNotLink = useCallback(() => {
        addParamsToUrl([
            {
                key: 'hasNotLink',
                value: filterHasNotLink ? undefined : 'true',
            },
        ]);
    }, [addParamsToUrl, filterHasNotLink]);

    const clearFilters = useCallback(() => {
        addParamsToUrl([
            {
                key: 'searchString',
                value: undefined,
            },
            {
                key: 'hasNotLink',
                value: undefined,
            },
            {
                key: 'pageNo',
                value: 0,
            },
            {
                key: 'pageSize',
                value: 10,
            },
        ]);
    }, [addParamsToUrl]);

    return (
        <Root>
            <FilterPanel>
                <InfoItem
                    className="filter-item"
                    title="Поиск"
                    value={<TextInput value={searchString} onChange={updateSearchString} />}
                />
                <FormControlLabel
                    className="filter-item filter-item-checkbox"
                    control={<Checkbox checked={hasNotLinkValue} onClick={toggleHasNotLink} />}
                    label="Без привязки"
                />
                <Button className="clear-filter-button" title="Сбросить фильтры" onClick={clearFilters} />
            </FilterPanel>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 'calc(100vh - 220px);' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">id в телеграмме</TableCell>
                                <TableCell align="left">Название чата</TableCell>
                                <TableCell align="left">Домашняя группа</TableCell>
                                <TableCell align="left">Служение</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {chatInfoList.map((chatInfo, index) => (
                                <ChatInfoItem
                                    item={chatInfo}
                                    key={index}
                                    queryParams={queryParams}
                                    bodyParams={bodyParams}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 100]}
                    component="div"
                    count={chatInfoTotalCount}
                    rowsPerPage={pageSizeValue}
                    page={pageNoValue}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Записей на странице"
                />
            </Paper>
            <LocalSpinner loading={isShowSpinner} size={30} />
            <BottomPadding />
        </Root>
    );
};

export default ChatInfoList;
