import styled, { css } from 'styled-components/macro';
import { colors } from '../../styles/variables';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;
`;

const Title = styled.div<{ size?: string }>`
    font-size: ${({ size }) => (size ? size : '12px')};
    margin-bottom: 3px;
    color: ${colors.line};
`;

const Value = styled.div<{ width?: number }>`
    white-space: break-spaces;
    font-size: 14px;

    ${({ width }) =>
        width
            ? css`
                  width: ${width}px;
              `
            : ''}
`;

export { Root, Title, Value };
