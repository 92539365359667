import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../../../global/components/button';
import { TextInput } from '../../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../../global/components/info-item';
import { InfoLine } from '../../../../../global/components/info-line';
import { goToState } from '../../../../../global/helpers/url-parse.helper';
import { Footer, Root } from './ov-cohort-create.styles';
import { useSpinner } from '../../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../../global/components/main-wrap/main-wrap.styles';
import { CreateOVCohortParams } from '../../../types/cohort/create-ov-cohort-params';
import { createOVCohortCard } from '../../../redux/cohort/ov-cohort.actions';

const OVCohortCreate = () => {
    const availablePage = useMemo(() => AuthService.availableForAdmin() || AuthService.hasOVPermission(), []);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [title, setTitle] = useState<string | null>(null);
    const goToList = useCallback(() => {
        goToState('/cohorts');
    }, []);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    const isValidForm = useMemo(() => !!title, [title]);

    const createCallback = useCallback(() => {
        if (!title) {
            return;
        }
        const params: CreateOVCohortParams = {
            title,
        };
        spinnerRunner(createOVCohortCard(params));
    }, [title, spinnerRunner]);

    return (
        <Root>
            <InfoItem title="Вы добавляете когорту" size={'h1'} />

            <InfoLine>
                <InfoItem title="Название *" value={<TextInput value={title} onChange={setTitle} />} />
            </InfoLine>
            <Footer>
                <Button title="Сохранить" onClick={createCallback} disabled={!isValidForm} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default OVCohortCreate;
