import * as React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../global/redux/store';
import { MainWrap } from '../global/components/main-wrap/main-wrap';
import HomeList from '../modules/home/pages/list/home-list.component';
import MembersList from '../modules/members/pages/list/members-list.component';
import MemberCard from '../modules/members/pages/card/member-card.component';
import MemberEdit from '../modules/members/pages/edit/member-edit.component';
import MemberCreate from '../modules/members/pages/create/member-create.component';
import { AuthWrap } from '../global/components/auth-wrap/auth-wrap';
import Login from '../auth/pages/login/login.component';
import Registration from '../auth/pages/registration/registration.component';
import MinistriesList from '../modules/ministries/pages/list/ministries-list.component';
import FamilyList from '../modules/family/pages/list/family-list.component';
import FamilyCreate from '../modules/family/pages/create/family-create.component';
import FamilyCard from '../modules/family/pages/card/family-card.component';
import FamilyEdit from '../modules/family/pages/edit/family-edit.component';
import MinistryCreate from '../modules/ministries/pages/create/ministries-create.component';
import MinistryCard from '../modules/ministries/pages/card/ministry-card.component';
import MinistryEdit from '../modules/ministries/pages/edit/ministry-edit.component';
import GroupCreate from '../modules/groups/pages/create/groups-create.component';
import GroupEdit from '../modules/groups/pages/edit/group-edit.component';
import GroupCard from '../modules/groups/pages/card/group-card.component';
import GroupsList from '../modules/groups/pages/list/groups-list.component';
import ResourcesCreate from '../modules/resources/pages/create/resources-create.component';
import ResourcesEdit from '../modules/resources/pages/edit/resources-edit.component';
import ResourcesCard from '../modules/resources/pages/card/resources-card.component';
import ResourcesList from '../modules/resources/pages/list/resources-list.component';
import ChatInfoList from '../modules/chat-info/pages/list/chat-info-list.component';
import ChatInfoCard from '../modules/chat-info/pages/card/chat-info-card.component';
// import MessagesList from '../modules/daily-messages/pages/list/messages-list.component';
// import MessageCreate from '../modules/daily-messages/pages/create/messages-create.component';
// import MessageCard from '../modules/daily-messages/pages/card/message-card.component';
// import MessageEdit from '../modules/daily-messages/pages/edit/message-edit.component';
import UsersList from '../modules/users/pages/list/users-list.component';
import UserCreate from '../modules/users/pages/create/users-create.component';
import UserCard from '../modules/users/pages/card/user-card.component';
import UserEdit from '../modules/users/pages/edit/user-edit.component';
import SettingsCard from '../modules/service/pages/card/settings-card.component';
import SettingsEdit from '../modules/service/pages/edit/settings-edit.component';
import OVCohortList from '../modules/ov/pages/cohort/list/ov-cohort-list.component';
import OVCohortCreate from '../modules/ov/pages/cohort/create/ov-cohort.component';
import OVCohortEdit from '../modules/ov/pages/cohort/edit/ov-cohort-edit.component';
import OVQuestionList from '../modules/ov/pages/questions/list/ov-question-list.component';
import OVCohortCard from '../modules/ov/pages/cohort/card/ov-cohort-card.component';
import { GroupsOnMap } from '../modules/groups/pages/groups-list-on-map/groups-list-on-map';

const MainRouter = () => {
    return (
        <Provider store={store}>
            <HashRouter>
                <MainWrap>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/registration/:hash" element={<Registration />} />

                        <Route
                            path="/*"
                            element={
                                <AuthWrap>
                                    <Routes>
                                        <Route index element={<HomeList />} />

                                        <Route path="members" element={<MembersList />} />
                                        <Route path="members/create" element={<MemberCreate />} />
                                        <Route path="members/:id" element={<MemberCard />} />
                                        <Route path="members/:id/edit" element={<MemberEdit />} />
                                        <Route path="ministries" element={<MinistriesList />} />
                                        <Route path="ministries/create" element={<MinistryCreate />} />
                                        <Route path="ministries/:id" element={<MinistryCard />} />
                                        <Route path="ministries/:id/edit" element={<MinistryEdit />} />
                                        <Route path="groups" element={<GroupsList />} />
                                        <Route path="groups/map" element={<GroupsOnMap />} />
                                        <Route path="groups/create" element={<GroupCreate />} />
                                        <Route path="groups/:id/edit" element={<GroupEdit />} />
                                        <Route path="groups/:id" element={<GroupCard />} />
                                        <Route path="family" element={<FamilyList />} />
                                        <Route path="family/create" element={<FamilyCreate />} />
                                        <Route path="family/:id" element={<FamilyCard />} />
                                        <Route path="family/:id/edit" element={<FamilyEdit />} />
                                        <Route path="resources" element={<ResourcesList />} />
                                        <Route path="resources/create" element={<ResourcesCreate />} />
                                        <Route path="resources/:id" element={<ResourcesCard />} />
                                        <Route path="resources/:id/edit" element={<ResourcesEdit />} />
                                        <Route path="chat-info" element={<ChatInfoList />} />
                                        <Route path="chat-info/:id" element={<ChatInfoCard />} />
                                        <Route path="users" element={<UsersList />} />
                                        <Route path="users/create" element={<UserCreate />} />
                                        <Route path="users/:id/edit" element={<UserEdit />} />
                                        <Route path="users/:id" element={<UserCard />} />
                                        <Route path="cohorts" element={<OVCohortList />} />
                                        <Route path="cohorts/create" element={<OVCohortCreate />} />
                                        <Route path="cohorts/:id" element={<OVCohortCard />} />
                                        <Route path="cohorts/:id/edit" element={<OVCohortEdit />} />
                                        <Route path="cohorts/questions" element={<OVQuestionList />} />
                                        <Route path="service-settings" element={<SettingsCard />} />
                                        <Route path="service-settings/edit" element={<SettingsEdit />} />
                                        {/* <Route path="daily-messages" element={<MessagesList/>} />
                                <Route
                                    path="daily-messages/create"
                                    element={<MessageCreate}
                                />
                                <Route
                                    path="daily-messages/:id/edit"
                                    element={<MessageEdit}
                                />
                                <Route
                                    path="daily-messages/:id"
                                    element={<MessageCard}
                                /> */}
                                    </Routes>
                                </AuthWrap>
                            }
                        />
                    </Routes>
                </MainWrap>
            </HashRouter>
        </Provider>
    );
};

export default MainRouter;
