import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components/macro';
import { colors, palette } from '../styles/variables';
import { scrollStyle } from '../styles/shared';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
  }
  
  body {
    background: #fafafa;
    font: 13px/1.231 sans-serif;
  }

  iframe {
    z-index: -1 !important;
    position: absolute !important;
    top: -100000000000000px !important;
    left: -100000000000000px !important;
  }

  table thead th {
    font-weight: 600 !important;
  }
  
  .local-button {
        height: 38px;
        margin-top: 18px;
  }

  .local-datapicker {
    height: 38px;
    width: 157px;
    margin-right: 0;
  }

  .ReactModal__Overlay {
    z-index: 10;
  }

  .ReactModal__Content {
    display: flex;
    justify-content: center;
    height: min-content;
    width: max-content;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
        
    @media (max-width: 950px) {
        max-width: 90%;
    } 
  }

  * {
      ${scrollStyle};
  }

  .action-icon-edit {
    cursor: pointer;
    fill: ${colors.buttonStaticBack} !important;
    margin-left: 20px;
  }
  .action-icon-delete {
    cursor: pointer;
    fill: ${palette.red5} !important;
    margin-left: 20px;
  }
  .action-icon-greenLight {
    cursor: pointer;
    fill: ${palette.greenLight} !important;
    margin-left: 20px;
        
    &.greenLightActive{
      fill: ${palette.greenLightActive} !important;
    }
  }
`;

export const LinkA = styled.a`
    cursor: pointer;
    color: ${palette.blue};
`;

export default GlobalStyle;
