import React, { Fragment } from 'react';

import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';
import { BreadcrumbsRoot, NavLinkRoot, NavLinkSeparator } from './breadcrumbs.styles';
import { routesNames } from '../../constants';

const BreadcrumbsItem = ({ match, lastItem }: BreadcrumbData & { lastItem: boolean }) => {
    const { pathname } = match;

    const route = pathname === '/' ? 'Главная' : routesNames[pathname];

    if (lastItem) {
        if (route) {
            return <>{route}</>;
        }

        if (pathname.indexOf('create') !== -1) {
            return <>Добавление</>;
        }

        if (pathname.indexOf('edit') !== -1) {
            return <>Редактирование</>;
        }

        return <>Информация</>;
    }

    const itemRender = route || 'В карточку';

    return (
        <Fragment key={pathname}>
            <NavLinkRoot to={pathname}>{itemRender}</NavLinkRoot>

            <NavLinkSeparator>/</NavLinkSeparator>
        </Fragment>
    );
};

export const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();

    if (!breadcrumbs.length) {
        return null;
    }

    return (
        <BreadcrumbsRoot>
            {breadcrumbs.map((item, i) => {
                const lastItem = breadcrumbs.length - 1 === i;

                return <BreadcrumbsItem
                    key={`${i}`}
                    match={item.match}
                    location={item.location}
                    breadcrumb={item.breadcrumb}
                    lastItem={lastItem}
                />;
            })}
        </BreadcrumbsRoot>
    );
};
