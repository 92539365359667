import React, { useCallback } from 'react';
import classNames from 'classnames';
import { ButtonStyle } from './index.styles';

export type TypeColor = 'secondary' | 'red' | 'green' | 'greenLight' | 'greenLightActive' | 'grayText' | 'blue' | 'create' | 'default';


type ButtonProps = {
    title: string;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    typeColor?: TypeColor;
};

export const Button = ({
    title,
    onClick,
    disabled,
    className,
    typeColor = 'default',
}: ButtonProps) => {
    const cssClass = classNames({ 'is-disabled': disabled, }, className,);

    const onClickCallback = useCallback(() => {
        if (disabled || !onClick) {
            return;
        }
        onClick();
    }, [onClick, disabled]);

    return (
        <ButtonStyle className={cssClass} onClick={onClickCallback} typeColor={typeColor}>
            {title}
        </ButtonStyle>
    );
};
