import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './user-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { InfoLine } from '../../../../global/components/info-line';
import { clearUserCard, deleteUserCard, loadUserCard } from '../../redux/users.actions';
import { getUserCard } from '../../redux/users.selectors';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useParams } from 'react-router-dom';

const UserCard = () => {
    const params = useParams();
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const userCard = useSelector(getUserCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearUserCard());
            setId(Number(params?.id));
            spinnerRunner(loadUserCard(Number(params?.id)));
        }
    }, [params?.id]);

    const goToList = useCallback(() => {
        goToState('/users');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/users/${params?.id}/edit`);
    }, [params?.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteUserCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id, deleteUserCard, spinnerRunner, goToList]);

    const permissions = useMemo(
        () => userCard?.permissions.map((el) => el.label).join(', ') || '',
        [userCard?.permissions],
    );

    if (!userCard) {
        return (
            <Root>
                <Header>
                    {showForAdmin && (
                        <>
                            <Button title="Перейти в список" onClick={goToList} />
                        </>
                    )}
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {showForAdmin && (
                    <>
                        <Button title="Редактировать" onClick={goToEdit} />
                        <Button title="Удалить" onClick={goToDelete} typeColor={'red'} />
                    </>
                )}
            </Header>
            <InfoLine>
                <InfoItem title="Логин" value={userCard.login} />
                <InfoItem title="Роль" value={userCard.role.label} />
            </InfoLine>
            <InfoItem title="Доступ к сервисам" value={permissions} />
            <InfoLine>
                <InfoItem
                    title="Член церкви"
                    value={
                        <div>
                            {!!userCard.member && (
                                <a href={`#/members/${userCard.member.id}`}>{userCard.member.name}</a>
                            )}
                        </div>
                    }
                />
            </InfoLine>
            <BottomPadding />
        </Root>
    );
};

export default UserCard;
