import { Indexable } from './types/indexable';

type DefaultErrorMessageType = {
    [index: string]: string;
};

export const backspaceKey = 'Backspace';
export const ctrlKey = 'Control';
export const commandKey = 'Meta';
export const plusKey = '=';
export const minusKey = '-';
export const shiftKey = 'Shift';
export const defaultToastTimeout: number = 5000;
export const defaultErrorMessage: DefaultErrorMessageType = {
    'Error: Request failed with status code 404': 'Ошибка! Страница не найдена',
    'Invalid resource owner credentials': 'Неверный логин или пароль',
    Unauthorized: 'Вы не авторизованы',
};

export enum LocalSpinnerKeysEnum {
    membersList = 'membersList',
    familyList = 'familyList',
    ministriesList = 'ministriesList',
    resourcesList = 'resourcesList',
    groupsList = 'groupsList',
    allGroups = 'allGroups',
    chatInfoList = 'chatInfoList',
    messagesList = 'messagesList',
    usersList = 'usersList',
    ovPeopleList = 'ovPeopleList',
    ovCohortList = 'ovCohortList',
}

export enum allPages {
    home = '/',
    members = '/members',
    ministries = '/ministries',
    groups = '/groups',
    family = '/family',
    resources = '/resources',
    chatInfo = '/chat-info',
    users = '/users',
    cohorts = '/cohorts',
    questionsCohorts = '/cohorts/questions',
    serviceSettings = '/service-settings',
}

export const routesNames: Indexable<string> = {
    [allPages.home]: 'Админка РБЦ',
    [allPages.members]: 'Участники',
    [allPages.ministries]: 'Служения',
    [allPages.groups]: 'Группы',
    [allPages.family]: 'Семьи',
    [allPages.resources]: 'Ресурсы',
    [allPages.chatInfo]: 'Информация о чатах',
    [allPages.users]: 'Пользователи',
    [allPages.cohorts]: 'Основы веры',
    [allPages.questionsCohorts]: 'Вопросы основ веры',
    [allPages.serviceSettings]: 'Настройки воскресного собрания',
};
