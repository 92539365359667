import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '../../../../../global/components/button';
import { InfoItem } from '../../../../../global/components/info-item';
import { goToState } from '../../../../../global/helpers/url-parse.helper';
import { Header, Root } from './ov-cohort-card.styles';
import { useSpinner } from '../../../../../global/hooks/use-spinner';
import { AuthService } from '../../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../../global/components/main-wrap/main-wrap.styles';
import { getOVCohortCard } from '../../../redux/cohort/ov-cohort.selectors';
import { clearOVCohortCard, deleteOVCohortCard, loadOVCohortCard } from '../../../redux/cohort/ov-cohort.actions';
import OVRegistrationList from '../../../components/registration/registration-list/ov-registration-list';
import { TableContainer } from '@mui/material';
import { useParams } from 'react-router-dom';

const OVCohortCard = () => {
    const params = useParams();
    const availablePage = useMemo(() => AuthService.availableForAdmin() || AuthService.hasOVPermission(), []);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const ovCohortCard = useSelector(getOVCohortCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearOVCohortCard());
            setId(Number(params?.id));
            spinnerRunner(loadOVCohortCard(Number(params?.id)));
        }
    }, [params?.id]);

    const goToList = useCallback(() => {
        goToState('/cohorts');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/cohorts/${params?.id}/edit`);
    }, [params?.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту когорту?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteOVCohortCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id]);

    if (!ovCohortCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button title="Редактировать" onClick={goToEdit} />
                {!ovCohortCard.isRegistrationOpen && <Button title="Удалить" onClick={goToDelete} typeColor={'red'} />}
            </Header>
            <InfoItem title="Название" value={ovCohortCard.title} />
            <TableContainer sx={{ maxHeight: 'calc(100vh - 161px);' }}>
                <OVRegistrationList cohortId={ovCohortCard.id} />
            </TableContainer>
            <BottomPadding />
        </Root>
    );
};

export default OVCohortCard;
