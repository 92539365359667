import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Content, Footer, Root, Title } from './send-list-modal.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { Button } from '../../../../global/components/button';
import { sendListProfiles } from '../../redux/members.actions';
import { GetMembersListBodyParams } from '../../types/get-members-list-body-params';

type SendListModalProps = {
    bodyParams: GetMembersListBodyParams;
    onClose: (withUpdate?: boolean) => void;
};

const SendListModal = (props: SendListModalProps) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [message, setMessage] = useState<string | null>('');

    const sendCallback = useCallback(async () => {
        if (!message) {
            return;
        }
        await spinnerRunner(sendListProfiles(props.bodyParams, message));
        props.onClose();
    }, [
        spinnerRunner,
        message,
        props.bodyParams,
        props.onClose,
    ]);

    const cancelCallback = useCallback(() => {
        props.onClose();
    }, [props.onClose]);

    const modalStyle: Modal.Styles = useMemo(() => ({
        content: {
            width: '600px',
            height: '400px',
        }
    }), []);

    return (
        <Modal
            isOpen
            style={modalStyle}
        >
            <Root>
                <Title>Рассылка для выбранных профилей</Title>
                <Content>
                    <InfoItem
                        title="Сообщение"
                        value={<TextInput
                            value={message}
                            onChange={setMessage}
                            asTextarea
                            style={{
                                width: '100%',
                                height: '200px',
                            }}
                        />}
                        valueStyle={{
                            width: '100%',
                        }}
                    />
                </Content>
                <Footer>
                    <Button
                        title="Закрыть"
                        onClick={cancelCallback}
                        typeColor={'secondary'}
                    />
                    <Button
                        title="Отправить"
                        onClick={sendCallback}
                    />
                </Footer>
            </Root>
        </Modal>
    );
};

export default SendListModal;
