import React, { useMemo } from 'react';
import moment from 'moment';
import { Root, Title, Value } from './index.styles';
import { Indexable } from '../../types/indexable';

type InfoItemProps = {
    title: string;
    value?: string | number | Date | null | JSX.Element;
    dateFormat?: string;
    className?: string;
    valueStyle?: React.CSSProperties;
    hideIsEmpty?: boolean;
    valueWidth?: number;
    size?: string;
};

const _size: Indexable<string> = {
    h1: '18px',
};

export const InfoItem = ({
    title,
    value,
    dateFormat,
    className,
    valueStyle,
    hideIsEmpty,
    valueWidth,
    size,
}: InfoItemProps) => {
    const visibleValue = useMemo(() => {
        if (value === null) {
            return '-';
        }
        if (!dateFormat) {
            return value;
        }
        return moment(`${value}`).format(dateFormat);
    }, [value, dateFormat]);

    if (hideIsEmpty && !value) {
        return null;
    }

    return (
        <Root className={className}>
            <Title size={size && _size[size]}>{title}</Title>

            {visibleValue && (
                <Value style={valueStyle} width={valueWidth}>
                    {visibleValue}
                </Value>
            )}
        </Root>
    );
};
