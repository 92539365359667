import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Content, Footer, Root, Title } from './pastor-note-modal.styles';
import { MemberList } from '../../models/member-list.model';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { Button } from '../../../../global/components/button';
import { savePastorNote } from '../../redux/members.actions';

type PastorNoteModalProps = {
    member: MemberList;
    onClose: (withUpdate?: boolean) => void;
};

const PastorNoteModal = (props: PastorNoteModalProps) => {
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const [note, setNote] = useState<string | null>(props.member.pastorNote);

    const saveCallback = useCallback(async () => {
        await spinnerRunner(savePastorNote(props.member.id, note));
        props.onClose(true);
    }, [
        spinnerRunner,
        note,
        props.member.id,
        props.onClose,
    ]);

    const cancelCallback = useCallback(() => {
        props.onClose(false);
    }, [props.onClose]);

    const modalStyle: Modal.Styles = useMemo(() => ({
        content: {
            width: '600px',
            height: '400px',
        }
    }), []);

    return (
        <Modal
            isOpen
            style={modalStyle}
        >
            <Root>
                <Title>Пасторские заметки</Title>
                <Content>
                    <InfoItem
                        title="Заметка"
                        value={<TextInput
                            value={note}
                            onChange={setNote}
                            asTextarea
                            style={{
                                width: '100%',
                                height: '200px',
                            }}
                        />}
                        valueStyle={{
                            width: '100%',
                        }}
                    />
                </Content>
                <Footer>
                    <Button
                        title="Закрыть"
                        onClick={cancelCallback}
                        typeColor={'secondary'}
                    />
                    <Button
                        title="Сохранить"
                        onClick={saveCallback}
                    />
                </Footer>
            </Root>
        </Modal>
    );
};

export default PastorNoteModal;
