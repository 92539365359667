import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    goToState,
} from '../../../../../global/helpers/url-parse.helper';
import { Root, Header } from './ov-question-list.styles';

import {
    makeGetIsShowLocalSpinner,
} from '../../../../../global/redux/global/global.selectors';
import { LocalSpinnerKeysEnum } from '../../../../../global/constants';
import {
    BottomPadding,
    LocalSpinner,
} from '../../../../../global/components/main-wrap/main-wrap.styles';
import { AuthService } from '../../../../../auth/services/AuthService';
import { TabBar } from '../../../../../global/components/tab-bar/tab-bar';
import { useOVTabs } from '../../../hooks/use-ov-tabs';

const OVQuestionList = () => {
    const availablePage = useMemo(
        () => AuthService.availableForAdmin() || AuthService.hasOVPermission(),
        [],
    );
    const { onTabClick, tabs } = useOVTabs('2');
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.ovCohortList),
    );
    useEffect(() => {
        if (!availablePage) {
            goToState('/');
            return;
        }
    }, []);

    return (
        <>
            <TabBar
                tabs={tabs}
                onTabClick={onTabClick}
            />
            <Root>
                <Header>
                </Header>
                <LocalSpinner loading={isShowSpinner} size={30} />
                <BottomPadding />
            </Root>
        </>
    );
};

export default OVQuestionList;
