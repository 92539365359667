import { toast } from 'react-toastify';
import { baseAsyncActionCreator } from '../../../../global/helpers/actions';
import { OVCohortActionsEnum, UpdateOVCohortAction } from './ov-cohort.types';
import { GetOVCohortListQueryParams } from '../../types/cohort/get-ov-cohort-list-query-params';
import { GetOVCohortListBodyParams } from '../../types/cohort/get-ov-cohort-list-body-params';
import { OVCohortRepository } from '../../repositories/ov-cohort.repository';
import { GlobalActionsEnum } from '../../../../global/redux/global/global.types';
import { OVCohort } from '../../models/cohort/ov-cohort.model';
import { CreateOVCohortParams } from '../../types/cohort/create-ov-cohort-params';
import { goToState } from '../../../../global/helpers/url-parse.helper';

const updateOVCohorts = (queryParams: GetOVCohortListQueryParams, bodyData: GetOVCohortListBodyParams) =>
    baseAsyncActionCreator<UpdateOVCohortAction>(async () => {
        const result = await OVCohortRepository.getList(queryParams, bodyData);
        return [
            OVCohortActionsEnum.updateOVCohorts,
            {
                cohorts: result.items,
                totalCount: result.totalCount,
            },
        ];
    });

const runOVCohort = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await OVCohortRepository.run(id);
        toast.success('Когорта успешно запущена');
        return [GlobalActionsEnum.empty, ''];
    });

const clearOVCohortCard = () =>
    baseAsyncActionCreator<OVCohort | null>(async () => {
        return [OVCohortActionsEnum.updateOVCohortCard, null];
    });

const loadOVCohortCard = (id: number) =>
    baseAsyncActionCreator<OVCohort | null>(async () => {
        const result = await OVCohortRepository.getCard(id);
        return [OVCohortActionsEnum.updateOVCohortCard, result];
    });

const createOVCohortCard = (params: CreateOVCohortParams, withoutRedirect?: boolean) =>
    baseAsyncActionCreator<string>(async () => {
        await OVCohortRepository.create(params);
        toast.success('Запись успешно добавлена!');
        if (!withoutRedirect) {
            goToState('/cohorts');
        }
        return [GlobalActionsEnum.empty, ''];
    });

const updateOVCohortCard = (id: number, params: CreateOVCohortParams) =>
    baseAsyncActionCreator<string>(async () => {
        await OVCohortRepository.edit(id, params);
        toast.success('Запись успешно обновлена!');
        goToState('/cohorts');
        return [GlobalActionsEnum.empty, ''];
    });

const deleteOVCohortCard = (id: number) =>
    baseAsyncActionCreator<string>(async () => {
        await OVCohortRepository.delete(id);
        toast.success('Запись успешно удалена!');
        return [GlobalActionsEnum.empty, ''];
    });

export {
    updateOVCohorts,
    runOVCohort,
    updateOVCohortCard,
    createOVCohortCard,
    loadOVCohortCard,
    clearOVCohortCard,
    deleteOVCohortCard,
};
