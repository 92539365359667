import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { injectStyle as injectToastifyStyle } from 'react-toastify/dist/inject-style';
import { keydownAction, keyupAction, updateLocationSearch } from '../../redux/global/global.actions';
import ContextMenu from '../context-menu';
import { SpinnerWrap } from './main-wrap.styles';
import { getIsShowGlobalSpinner } from '../../redux/global/global.selectors';
import { defaultToastTimeout } from '../../constants';
import { ContextMenuProvider } from '../context-menu/context-menu-provider/context-menu-provider';
import { LocalSpinner } from '../../../global/components/main-wrap/main-wrap.styles';

type MainWrapProps = {
    children: React.ReactElement;
};

Modal.setAppElement('#root');

export const MainWrap: React.FC<MainWrapProps> = (props) => {
    const dispatch = useDispatch();
    const isShowGlobalSpinner = useSelector(getIsShowGlobalSpinner);

    useEffect(() => {
        injectToastifyStyle();
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);

        window.onhashchange = () => {
            dispatch(updateLocationSearch(window.location.hash));
        };

        return () => {
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    const onKeyDown = (e: KeyboardEvent) => {
        dispatch(keydownAction(e.key));
    };

    const onKeyUp = (e: KeyboardEvent) => {
        dispatch(keyupAction(e.key));
    };

    return (
        <ContextMenuProvider>
            {props.children}
            <ContextMenu />
            <ToastContainer
                position="top-right"
                autoClose={defaultToastTimeout}
                closeOnClick
                pauseOnFocusLoss={false}
                pauseOnHover
            />
            {isShowGlobalSpinner && <SpinnerWrap />}
            <LocalSpinner loading={isShowGlobalSpinner} size={50} />
        </ContextMenuProvider>
    );
};
