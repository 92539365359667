import React from 'react';
import { HomeListItem, HomeListItemIn, Root } from './home-list.styles';
import { useDispatch } from 'react-redux';
import { renderMenu } from '../../../../global/components/header/helpers';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { setIsOpenMenu } from '../../../../global/redux/global/global.actions';

type CreateSendListFromCSVProps = {
    label?: string;
};

const HomeListComponent = ({ label }: CreateSendListFromCSVProps) => {
    const dispatch = useDispatch();

    const menu = renderMenu();

    const goToPages = (page: string) => {
        goToState(page);

        dispatch(setIsOpenMenu(false));
    };

    return (
        <Root>
            {menu.map((item) => {
                const { page, name, show, classNameMenu } = item;

                if (show) {
                    return (
                        <HomeListItem className={classNameMenu} onClick={() => goToPages(page)} key={page}>
                            <HomeListItemIn>{name}</HomeListItemIn>
                        </HomeListItem>
                    );
                }
            })}
        </Root>
    );
};

export default HomeListComponent;
