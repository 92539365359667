import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button } from '../../../../global/components/button';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { InfoItem } from '../../../../global/components/info-item';
import { InfoLine } from '../../../../global/components/info-line';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { Footer, Header, Root } from './resources-edit.styles';
import { getResourcesCard } from '../../redux/resources.selectors';
import { clearResourcesCard, loadResourcesCard, updateResourcesCard } from '../../redux/resources.actions';
import { ResourcesTypeItem, resourcesTypeOptions } from '../../models/resources-type.model';
import { ResourcesPlatformItem, resourcesPlatformOptions } from '../../models/resources-platform.model';
import { ResourcesRestrictionItem, resourcesRestrictionOptions } from '../../models/resources-restriction.model';
import { CreateResourcesParams } from '../../types/create-resources-params';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { EntityForSelect } from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import { loadChatInfoForSelect, parseChatInfoItemToEntityForSelect } from '../../../chat-info/helpers/api.helpers';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import { useParams } from 'react-router-dom';

const ResourcesEdit = () => {
    const params = useParams();
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const resourcesCard = useSelector(getResourcesCard);
    const [id, setId] = useState<number | null>(null);
    const spinnerRunner = useSpinner(dispatch);
    const [name, setName] = useState<string | null>(null);
    const [description, setDescription] = useState<string | null>(null);
    const [url, setUrl] = useState<string | null>(null);
    const [chatInfo, setChatInfo] = useState<EntityForSelect | null>(null);
    const [type, setType] = useState<ResourcesTypeItem | null>(null);
    const [platform, setPlatform] = useState<ResourcesPlatformItem | null>(null);
    const [restriction, setRestriction] = useState<ResourcesRestrictionItem | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearResourcesCard());
            setId(Number(params?.id));
            dispatch(loadResourcesCard(Number(params?.id)));
        }
    }, [params?.id]);

    useEffect(() => {
        if (resourcesCard) {
            setId(resourcesCard.id);
            setName(resourcesCard.name);
            setDescription(resourcesCard.description);
            setUrl(resourcesCard.url);
            setChatInfo(resourcesCard.chat ? parseChatInfoItemToEntityForSelect(resourcesCard.chat) : null);
            setType(resourcesCard.type);
            setPlatform(resourcesCard.platform);
            setRestriction(resourcesCard.restriction);
        }
    }, [resourcesCard]);

    const isValidForm = useMemo(() => !!name && !!type && !!platform, [name, type, platform]);

    const saveCallback = useCallback(() => {
        if (!id || !name || !type || !platform) {
            return;
        }
        const params: CreateResourcesParams = {
            name,
            description,
            url,
            chatId: chatInfo ? `${chatInfo.id}` : null,
            type: type.value,
            platform: platform.value,
            restriction: restriction?.value || null,
        };
        spinnerRunner(updateResourcesCard(id, params));
    }, [id, name, url, description, chatInfo, type, platform, restriction, spinnerRunner, updateResourcesCard]);

    const goToList = useCallback(() => {
        goToState('/resources');
    }, []);

    if (!resourcesCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <InfoItem title="Вы редактируете ресурс" size={'h1'} />
            <InfoLine>
                <InfoItem title="Название *" value={<TextInput value={name} onChange={setName} />} />
                <InfoItem title="Описание" value={<TextInput value={description} onChange={setDescription} />} />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Тип *"
                    value={<Select options={resourcesTypeOptions} defaultValue={type} onChange={setType} isClearable />}
                />
                <InfoItem
                    title="Платформа *"
                    value={
                        <Select
                            options={resourcesPlatformOptions}
                            defaultValue={platform}
                            onChange={setPlatform}
                            isClearable
                        />
                    }
                />
                <InfoItem title="URL" value={<TextInput value={url} onChange={setUrl} />} />
                <SingleSelectEntity
                    label="Чат в телеграмме"
                    value={chatInfo}
                    onChange={setChatInfo}
                    loadOptions={loadChatInfoForSelect}
                />
            </InfoLine>
            <InfoLine>
                <InfoItem
                    title="Ограничения"
                    value={
                        <Select
                            options={resourcesRestrictionOptions}
                            defaultValue={restriction}
                            onChange={setRestriction}
                            isClearable
                        />
                    }
                />
            </InfoLine>
            <Footer>
                <Button title="Сохранить" onClick={saveCallback} disabled={!isValidForm} typeColor={'blue'} />
            </Footer>
            <BottomPadding />
        </Root>
    );
};

export default ResourcesEdit;
