import { useCallback, useMemo } from 'react';
import { CallbackTabParam, TabItemType } from '../../../../global/components/tab-bar/tab-bar';
import { goToState } from '../../../../global/helpers/url-parse.helper';

export const useOVTabs = (id: string) => {
    const tabs = useMemo<TabItemType[]>(
        () =>
            [
                {
                    title: 'Когорты',
                    id: '1',
                },
                {
                    title: 'Вопросы',
                    id: '2',
                },
            ].map((el) => ({
                ...el,
                isActive: el.id === id,
            })),
        [id],
    );

    const onTabClick = useCallback((params: CallbackTabParam) => {
        if (params.id === '1') {
            goToState('/cohorts');
            return;
        }
        if (params.id === '2') {
            goToState('/cohorts/questions');
            return;
        }
    }, []);

    return {
        tabs,
        onTabClick,
    };
};
