import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, MemberItem, Root } from './family-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { getFamilyCard } from '../../redux/family.selectors';
import { clearFamilyCard, deleteFamilyCard, loadFamilyCard } from '../../redux/family.actions';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useParams } from 'react-router-dom';

const FamilyCard = () => {
    const params = useParams();
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const familyCard = useSelector(getFamilyCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearFamilyCard());
            setId(Number(params?.id));
            spinnerRunner(loadFamilyCard(Number(params?.id)));
        }
    }, [params?.id]);

    const goToList = useCallback(() => {
        goToState('/family');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/family/${params?.id}/edit`);
    }, [params?.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteFamilyCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);

    if (!familyCard) {
        return (
            <Root>
                <Header>
                    {showForAdmin && (
                        <>
                            <Button title="Перейти в список" onClick={goToList} />
                        </>
                    )}
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {showForAdmin && (
                    <>
                        <Button title="Редактировать" onClick={goToEdit} />
                        <Button title="Удалить" onClick={goToDelete} typeColor={'red'} />
                    </>
                )}
            </Header>
            <InfoItem title="Название" value={familyCard.name} />
            <InfoItem title="Кол-во детей" value={familyCard.childCount} />
            <InfoItem
                title="Участники"
                value={
                    <div>
                        {familyCard.members.map((el) => (
                            <MemberItem key={el.id}>
                                <a href={`#/members/${el.id}`}>{el.name}</a>
                            </MemberItem>
                        ))}
                    </div>
                }
            />
            <BottomPadding />
        </Root>
    );
};

export default FamilyCard;
