import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './resources-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { InfoLine } from '../../../../global/components/info-line';
import { clearResourcesCard, deleteResourcesCard, loadResourcesCard } from '../../redux/resources.actions';
import { getResourcesCard } from '../../redux/resources.selectors';
import { AuthService } from '../../../../auth/services/AuthService';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { useParams } from 'react-router-dom';

const ResourcesCard = () => {
    const params = useParams();
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const resourcesCard = useSelector(getResourcesCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (params?.id && Number(params?.id) !== id) {
            dispatch(clearResourcesCard());
            setId(Number(params?.id));
            spinnerRunner(loadResourcesCard(Number(params?.id)));
        }
    }, [params?.id]);

    const goToList = useCallback(() => {
        goToState('/resources');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/resources/${params?.id}/edit`);
    }, [params?.id]);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteResourcesCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id, deleteResourcesCard, spinnerRunner, goToList]);

    if (!resourcesCard) {
        return (
            <Root>
                <Header>
                    <Button title="Перейти в список" onClick={goToList} />
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Button title="Редактировать" onClick={goToEdit} />
                <Button title="Удалить" onClick={goToDelete} typeColor={'red'} />
            </Header>
            <InfoLine>
                <InfoItem title="Название" value={resourcesCard.name} />
                <InfoItem title="Описание" value={resourcesCard.description} />
            </InfoLine>
            <InfoLine>
                <InfoItem title="Тип" value={resourcesCard.type.label} />
                <InfoItem title="Платформа" value={resourcesCard.platform.label} />
                <InfoItem title="URL" value={resourcesCard.url} />
                <InfoItem
                    title="Чат в телеграмме"
                    value={
                        <div>
                            {resourcesCard.chat && (
                                <a href={`#/chat-info/${resourcesCard.chat.id}`}>{resourcesCard.chat.chatName}</a>
                            )}
                        </div>
                    }
                />
            </InfoLine>
            <InfoLine>
                <InfoItem title="Ограничения" value={resourcesCard.restriction?.label || '-'} />
            </InfoLine>
            <BottomPadding />
        </Root>
    );
};

export default ResourcesCard;
